<template>
  <b-nav-item-dropdown menu-class="navbar-language" no-caret>
    <template slot="button-content">
      <span :class="getCurrentFlagClass()"></span>
      <span class="name d-none d-sm-inline mr-1">{{ currentLocale.toUpperCase() }}</span> <b class="caret"></b>
    </template>
    <b-dropdown-item v-for="loc in this.localeList" v-bind:key="loc" @click="setLanguage(loc)"><span
        :class="getFlagClass(loc)" :title="loc"></span> {{ $t("languages." + loc) }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>

import {getValidLocaleList, setLocale} from "@/_helpers/locale_helper";

export default {
  name: "LanguageSelect",
  data() {
    return {
      localeList: getValidLocaleList()
    }
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    setLanguage(ev) {
      setLocale(ev, this);
    },
    getCurrentFlagClass() {
      return "flag-icon mr-1 flag-icon-" + this.currentLocale;
    },
    getFlagClass(locale) {
      return "flag-icon flag-icon-" + locale;
    }
  }
}
</script>