import { config } from "@/config/config";
import { getInitialGridCellConfig } from "@/config/stores/common";

export function setEmptyGrids(numRows, numCols) {
  const grid = [];

  let initialGridCell = getInitialGridCellConfig(config.games.props.kakuro.id);
  // Manually setting it to true so user in initial state cannot click on anything
  initialGridCell.locked = true;
  initialGridCell.type = "E";

  for (let i = 0; i < numRows; i++) {
    grid.push([]);
    for (let j = 0; j < numCols; j++) {
      grid[i].push(initialGridCell);
    }
  }

  return {
    numRows: numRows,
    numCols: numCols,
    gridId: null,
    grid: grid,
  };
}

export function setGrids(data) {
  const numRows = data.num_rows;
  const numCols = data.num_cols;
  const gridId = data.gridId;
  const grid = [];

  for (let i = 0; i < data.grid.length; i++) {
    grid.push([]);
    for (let j = 0; j < data.grid[i].length; j++) {
      let gridCell = getInitialGridCellConfig(config.games.props.kakuro.id);

      gridCell.type = data.grid[i][j].t;

      if (gridCell.type === "L") {
        gridCell.solution = data.grid[i][j].v;
        gridCell.value = data.grid[i][j].uv;
        gridCell.original_value = "";
      } else if (gridCell.type === "H") {
        gridCell.value = "Hint!!!";
        gridCell.locked = true;

        let he = "he" in data.grid[i][j] ? data.grid[i][j].he : null;
        let hs = "hs" in data.grid[i][j] ? data.grid[i][j].hs : null;

        if (he !== null) {
          gridCell.he = he;
        }

        if (hs !== null) {
          gridCell.hs = hs;
        }
      } else if (gridCell.type === "E") {
        gridCell.locked = true;
      }

      grid[i].push(gridCell);
    }
  }

  return {
    numRows: numRows,
    numCols: numCols,
    gridId: gridId,
    grid: grid,
  };
}

export function setState(state, grids) {
  state.gridId = grids.gridId;
  state.grid = grids.grid;
  state.numRows = grids.numRows;
  state.numCols = grids.numCols;
}
