import {getInitialGridCellConfig} from "@/config/stores/common";
import {config} from "@/config/config";
import {includes} from "lodash";

export function setEmptyGrids(maxWidth, numRows, numCols) {
    let grid = [];
    let gridSymbols = [];

    // Fill gridSymbols
    gridSymbols[0] = [];
    for (let i = 0; i < maxWidth; i++) {
        gridSymbols[0].push("");
    }

    let initialCellConfig = getInitialGridCellConfig(
        config.games.props.memory.id
    );
    initialCellConfig.locked = true;

    for (let i = 0; i < numRows; i++) {
        grid[i] = [];
        for (let j = 0; j < numCols; j++) {
            grid[i].push(initialCellConfig);
        }
    }

    return {
        gridId: null,
        grid: grid,
        gridSymbols: gridSymbols,
    };
}

export function setState(state, grids) {
    state.gridId = grids.gridId;
    state.grid = grids.grid;
    state.gridSymbols = grids.gridSymbols;
    state.words = grids.words;
    state.foundWords = grids.foundWords;
    state.maxSelectedCoords = grids.maxSelectedCoords;

    state.foundWords = [];
    if ("foundWords" in grids && grids.foundWords !== undefined) {
        state.foundWords = grids.foundWords;
    }
}

export function setGrids(maxWidth, data) {
    let grid = [];
    let gridSymbols = [];

    let symbols = Object.keys(data.value_coords);
    symbols.sort();

    let gIndex = 0;
    gridSymbols[gIndex] = [];
    for (let i = 0; i < symbols.length; i++) {
        if (gridSymbols[gIndex].length === maxWidth) {
            gIndex++;
            gridSymbols[gIndex] = [];
        }
        gridSymbols[gIndex].push(symbols[i]);
    }

    for (let i = 0; i < data.grid.length; i++) {
        grid[i] = [];
        for (let j = 0; j < data.grid[i].length; j++) {
            const gObj = {
                original_value: data.grid[i][j],
                solution: data.grid[i][j],
                selected: false,
            };

            if (data.completed || includes(data.found_symbols, data.grid[i][j])) {
                gObj.value = data.grid[i][j];
            }

            grid[i].push(gObj);
        }
    }

    return {
        gridId: data.gridId,
        grid: grid,
        gridSymbols: gridSymbols,
        words: data.value_coords,
        foundWords: data.found_symbols,
        maxSelectedCoords: data.num_occurrences,
    };
}