export function initTasks(taskData, isAssignment = false) {
    let tasks = [];
    taskData.forEach((singleTask) => {
        tasks.push({
            ...singleTask,
            completed: singleTask.completed || false,
            correct: singleTask.correct || false,
            selected: singleTask.selected || null,
        })
    })

    // current task to be solved is first one that is not solved yet
    let currentTaskIdx = 0;
    if (isAssignment){
        currentTaskIdx = -1;
    }
    else {
        const notCompletedTask = tasks.find(x => !x.completed);
        currentTaskIdx = tasks.indexOf(notCompletedTask);
        if(currentTaskIdx === -1) {
            currentTaskIdx = tasks.length - 1;
        }
    }

    return {
        currentTaskIdx,
        tasks,
    }
}

export function setState(game, gameId, state, taskData) {
    state.game = game;
    state.gameId = gameId;
    state.currentTaskIdx = taskData.currentTaskIdx;
    state.tasks = taskData.tasks;
}