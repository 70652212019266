import {config} from "@/config/config";
import {getInitialGridCellConfig} from "@/config/stores/common";

export function setEmptyGrids(numRows, numCols, hintRowLength) {
    const grid = [];

    let hintsS = [[]];
    let hintsE = [[]];

    let initialGridCell = getInitialGridCellConfig(
        config.games.props.mathCrossword.id
    );
    // Manually setting it to true so user in initial state cannot click on anything
    initialGridCell.locked = true;
    initialGridCell.type = "E";

    for (let i = 0; i < hintRowLength; i++) {
        hintsE[0].push({t: "e"});
        hintsE[0].push({t: "e"});
        hintsS[0].push({t: "e"});
        hintsS[0].push({t: "e"});
    }

    for (let i = 0; i < numRows; i++) {
        grid.push([]);
        for (let j = 0; j < numCols; j++) {
            grid[i].push(initialGridCell);
        }
    }

    return {
        numRows: numRows,
        numCols: numCols,
        gridId: null,
        grid: grid,
        hintsE: hintsE,
        hintsS: hintsS,
    };
}

export function setGrids(data, hintRowLength) {
    const numRows = data.num_rows;
    const numCols = data.num_cols;
    const gridId = data.gridId;
    const grid = [];

    let hintsS = [[]];
    let hintsSIndex = 0;

    let hintsE = [[]];
    let hintsEIndex = 0;

    let hintNumber = 1;

    hintRowLength = hintRowLength * 2;

    for (let i = 0; i < data.grid.length; i++) {
        grid.push([]);
        for (let j = 0; j < data.grid[i].length; j++) {
            let gridCell = getInitialGridCellConfig(
                config.games.props.mathCrossword.id
            );

            gridCell.type = data.grid[i][j].t;

            if (gridCell.type === "L") {
                gridCell.solution = data.grid[i][j].v;
                gridCell.value = data.grid[i][j].uv;
                gridCell.original_value = "";
            } else if (gridCell.type === "H") {
                gridCell.value = hintNumber;
                gridCell.locked = true;

                let he = "he" in data.grid[i][j] ? data.grid[i][j].he : null;
                let hs = "hs" in data.grid[i][j] ? data.grid[i][j].hs : null;

                if (he !== null) {
                    he = `${he.o1} ${he.op} ${he.o2}`;
                    gridCell.he = he;

                    let hn = {
                        t: "n",
                        v: hintNumber,
                    };

                    let hv = {
                        t: "h",
                        v: he,
                    };

                    if (hintsE[hintsEIndex].length === hintRowLength) {
                        hintsEIndex = hintsEIndex + 1;
                        hintsE.push([]);
                    }

                    hintsE[hintsEIndex].push(hn);
                    hintsE[hintsEIndex].push(hv);
                }

                if (hs !== null) {
                    hs = `${hs.o1} ${hs.op} ${hs.o2}`;
                    gridCell.hs = hs;

                    let hn = {
                        t: "n",
                        v: hintNumber,
                    };

                    let hv = {
                        t: "h",
                        v: hs,
                    };

                    if (hintsS[hintsSIndex].length === hintRowLength) {
                        hintsSIndex = hintsSIndex + 1;
                        hintsS.push([]);
                    }

                    hintsS[hintsSIndex].push(hn);
                    hintsS[hintsSIndex].push(hv);
                }

                hintNumber = hintNumber + 1;
            } else if (gridCell.type === "E") {
                gridCell.locked = true;
            }

            grid[i].push(gridCell);
        }
    }

    return {
        numRows: numRows,
        numCols: numCols,
        gridId: gridId,
        grid: grid,
        hintsE: hintsE,
        hintsS: hintsS,
    };
}

export function setState(state, grids) {
    state.gridId = grids.gridId;
    state.grid = grids.grid;
    state.numRows = grids.numRows;
    state.numCols = grids.numCols;
    state.hintsE = grids.hintsE;
    state.hintsS = grids.hintsS;
}
