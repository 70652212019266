import { config } from "@/config/config";

export function listAssignmentsUrl(entity, entityId) {
    return `${config.apiUrl}${config.urls.baseAssignments}/${entity}/${entityId}`
}

export function listAssignmentsMethod() {
    return config.assignments.listAssignments.method;
}

export function addAssignmentUrl() {
    return `${config.apiUrl}${config.urls.baseAssignments}`
}

export function addAssignmentMethod() {
    return config.assignments.addAssignment.method;
}

export function getAssignmentUrl(assignmentId) {
    return `${config.apiUrl}${config.urls.baseAssignments}/${assignmentId}`
}

export function getAssignmentMethod() {
    return config.assignments.getAssignment.method;
}

export function getMyAssignmentsUrl() {
    return `${config.apiUrl}${config.urls.baseAssignments}/${config.assignments.getMyAssignments.url}`
}

export function getMyAssignmentsMethod() {
    return config.assignments.getMyAssignments.method;
}

export function getMyAssignmentUrl(assignmentId) {
    return `${config.apiUrl}${config.urls.baseAssignments}/${config.assignments.getMyAssignments.url}/${assignmentId}`
}

export function getMyAssignmentMethod() {
    return config.assignments.getMyAssignments.method;
}

export function getAssignmentPriceUrl() {
    return `${config.apiUrl}${config.urls.baseAssignments}/${config.assignments.getAssignmentPrice.url}`
}

export function getAssignmentPriceMethod() {
    return config.assignments.getAssignmentPrice.method;
}

export function updateMyAssignmentUrl(assignmentId) {
    return `${config.apiUrl}${config.urls.baseAssignments}/${config.assignments.getMyAssignments.url}/${assignmentId}`
}

export function updateMyAssignmentMethod() {
    return config.assignments.updateMyAssignment.method;
}

export function archiveMyAssignmentUrl(assignmentId) {
    return `${config.apiUrl}${config.urls.baseAssignments}/${config.assignments.getMyAssignments.url}/${assignmentId}/archive`
}

export function archiveMyAssignmentMethod() {
    return config.assignments.archiveMyAssignment.method;
}

export function archiveAssignmentUrl(assignmentId) {
    return `${config.apiUrl}${config.urls.baseAssignments}/${assignmentId}/archive`
}

export function archiveAssignmentMethod() {
    return config.assignments.archiveAssignment.method;
}

export function isTimeOverdue(assignment) {
    const now = Date.now();
    const end = new Date(assignment.endDate).getTime();
    return now > end;
}

export function isTimeInProgress(assignment) {
  const now = Date.now();
  const start = new Date(assignment.startDate).getTime();
  const end = new Date(assignment.endDate).getTime();
  return now < end && now > start;
}

export function isTimeUpcoming(assignment) {
  const now = Date.now();
  const start = new Date(assignment.startDate).getTime();
  return now < start;
}

export function isCompleted(assignment) {
  return assignment?.completed;
}

export function isAlreadyStarted(assignment) {
  return (assignment?.numSolvedTasks || 0) > 0;
}

export function getAssignmentBadge(assignment, vueInstance) {
  let badgeClass = "rm-solved-with-color";
  let i18name = "inProgress";
  if (isTimeUpcoming(assignment)) {
    badgeClass = "rm-selected-with-color";
    i18name = "upcoming";
  }
  else if (isTimeOverdue(assignment)) {
    badgeClass = "rm-wrong-with-color";
    i18name = "completed";
  }
  return `<div class="badge ${badgeClass}">${ vueInstance.$t("assignments.statusesSingular." + i18name) }</div>`
}

export function getAssignmentTitleHeader(assignment, vueInstance) {
  return `<div class="d-flex flex-row justify-content-between"><span>${assignment.name}</span>${getAssignmentBadge(assignment, vueInstance)}</div>`;
}