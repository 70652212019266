import Vue from "vue";
import VueX from "vuex";

import VueGtag from "vue-gtag";

// plugins
import VueRouter from "vue-router";
import VueBootstrap from "bootstrap-vue";
import VueInsProgressBar from "vue-ins-progress-bar";
import VueSweetalert2 from "vue-sweetalert2";
import VuePanel from "./plugins/panel/";
import VueSelect from "vue-select";
import VueSlider from "vue-slider-component";
import VueCustomScrollbar from "vue-custom-scrollbar";


// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

// plugins css
import "bootstrap-vue/dist/bootstrap-vue.css";
import "flag-icon-css/css/flag-icon.min.css";
import "vue-select/dist/vue-select.css";
import "vue-slider-component/theme/antd.css";

// color admin css
import "./assets/css/google/app.min.css";
import "./scss/vue.scss";

// custom css
import "./assets/css/custom.css";

import App from "./App.vue";

Vue.config.productionTip = false;

Vue.use(VueX);
Vue.use(VueRouter);
Vue.use(VueBootstrap);
Vue.use(VueSweetalert2);
Vue.use(VuePanel);

Vue.use(VueInsProgressBar, {
    position: "fixed",
    show: true,
    height: "3px",
});
Vue.component("v-select", VueSelect);
Vue.component("vue-slider", VueSlider);
Vue.component("vue-custom-scrollbar", VueCustomScrollbar);

import router from "./config/PageRoutes";
import store from "./config/stores/PageStores";
import i18n from './i18n'

Vue.use(
    VueGtag,
    {
        config: {id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID},
    },
    router
);

new Vue({
    render: (h) => h(App),
    router,
    i18n,
    store
}).$mount("#app");
