import {config} from "@/config/config";
import {includes} from "lodash";
import {getInitialGridCellConfig} from "@/config/stores/common";

export function setEmptyGrids(numRows, numCols, numHintCols) {
    let grid = [];

    let words = [];
    let wIndex = 0;
    words[wIndex] = [];

    let initialCellConfig = getInitialGridCellConfig(
        config.games.props.wordSearch.id
    );
    // Manually setting it to true so user in initial state cannot click on anything
    initialCellConfig.locked = true;

    for (let i = 0; i < numHintCols; i++) {
        if (words[wIndex].length === numHintCols) {
            wIndex++;
            words[wIndex] = [];
        }
        words[wIndex].push(initialCellConfig);
    }

    for (let i = 0; i < numRows; i++) {
        grid[i] = [];
        for (let j = 0; j < numCols; j++) {
            grid[i].push(initialCellConfig);
        }
    }

    return {
        gridId: null,
        grid: grid,
        words: words,
        wordSize: 4,
    };
}

export function setGrids(data) {
    const maxWidth = 4;
    let markedCells = [];

    let grid = [];

    let words = [];
    let wIndex = 0;
    words[wIndex] = [];

    for (let i = 0; i < data.words.length; i++) {
        const wFound = includes(data.found_words, data.words[i].w);
        data.words[i].f = wFound;

        if (wFound) {
            markedCells.push(
                ...data.words[i].c.map(
                    (el) => el[0].toString() + "," + el[1].toString()
                )
            );
        }

        if (words[wIndex].length === maxWidth) {
            wIndex++;
            words[wIndex] = [];
        }
        words[wIndex].push(data.words[i]);
    }

    for (let i = 0; i < data.grid.length; i++) {
        grid[i] = [];
        for (let j = 0; j < data.grid[i].length; j++) {
            const gObj = {
                value: data.grid[i][j],
                selected: false,
                marked: includes(markedCells, i.toString() + "," + j.toString()),
            };
            grid[i].push(gObj);
        }
    }

    return {
        gridId: data.gridId,
        grid: grid,
        words: words,
        wordSize: data.word_length,
    };
}

export function setState(state, grids) {
    state.words = grids.words;
    state.gridId = grids.gridId;
    state.grid = grids.grid;
    state.wordSize = grids.wordSize;
}
