import {authHeader, corsHeader} from "./auth-header";
import {includes} from "lodash";
import {messageSettings} from "@/i18n/messages";

function updateOptions(options) {
    const update = {...options};
    let tokenEntry = authHeader();
    let corsEntry = corsHeader();

    update.headers = {
        ...update.headers,
        ...tokenEntry,
        ...corsEntry,
    };

    return update;
}

export function fetcher(url, options) {
    return fetch(url, updateOptions(options));
}

export function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export function handleError(error, router, vue_instance = null) {
    const routeToLoginErrors = [
        "MISSING_AUTH_HEADER",
        "MISSING_AUTH_TOKEN",
        "AUTH_TOKEN_EXPIRED",
        "AUTH_TOKEN_ERROR",
        "AUTH_NO_USER_ERROR",
        "AUTH_TOKEN_WRONG_DATA",
    ];

    const routeToHomePageErrors = ["E-MENTORSHIP-NOT_A_MENTOR"]

    if (includes(routeToLoginErrors, error)) {
        router.push("/login?reset=true");
    } else if (includes(routeToHomePageErrors, error)) {
        router.push("/");
    } else {
        showSweetAlert(error, vue_instance);
    }
}

export function showSweetAlert(message, vue_instance = null) {
    if (vue_instance == null) {
        return;
    }

    if (message == null || typeof message !== "string") {
        return;
    }

    // Close existing alert
    if (vue_instance.$swal.isVisible()) {
        vue_instance.$swal.close();
    }

    let splitMessage = message.split("-");
    if (splitMessage.length === 3) {
        const mType = splitMessage[0];
        const mModule = splitMessage[1];
        const mMsg = splitMessage[2];

        let typeMapper = {
            E: "error",
            I: "info",
            S: "success",
            W: "warning",
        };

        let showConfirmButton = true;
        let showCancelButton = false;

        let messageTitle = vue_instance.$t("messages." + message + ".title");
        let messageDescription = vue_instance.$t("messages." + message + ".description");
        try {
            let msgObj = messageSettings[mType][mModule][mMsg];
            if ("showConfirmButton" in msgObj) {
                showConfirmButton = msgObj.showConfirmButton;
            }

            if ("showCancelButton" in msgObj) {
                showCancelButton = msgObj.showCancelButton;
            }

        } catch (err) {
            return;
        }

        vue_instance.$swal.fire({
            icon: typeMapper[mType],
            title: messageTitle,
            html: messageDescription,
            showConfirmButton: showConfirmButton,
            showCancelButton: showCancelButton,
            confirmButtonColor: "#4285f4", // Primary color
            cancelButtonColor: "#ff9800" // Warning color
        });
    }
}
