<template>
  <ul class="nav">
    <li v-if="account.user" class="nav-profile">
      <a href="javascript:;" v-on:click="expand()">
        <div class="cover with-shadow"></div>
        <div class="info">
          <b :class="caretClass" style="line-height: inherit!important;"></b>
          <div class="d-flex">
            <span class="m-r-10">{{ account.user.name }}</span>
            <small v-if="isMentor" class="m-r-5"><b-badge variant="primary">{{ $t("mentorship.mentor") }}</b-badge></small>
          </div>
        </div>
      </a>
    </li>
    <li v-else class="nav-profile">
      <router-link to="/login" class="sidebar-user-option"
      ><i class="fa fa-sign-in-alt"></i>{{ $t("menu.logIn") }}
      </router-link
      >
    </li>
    <li v-if="account.user">
      <ul
          class="nav nav-profile"
          v-bind:class="{
          'd-block':
            this.stat === 'expand' && !this.pageOptions.pageSidebarMinified,
          'd-none': this.stat === 'collapse',
        }"
      >
        <li>
          <router-link to="/credit-buy" class="sidebar-user-option"
          ><i class="fas fa-carrot fa-carrot-rm"></i
          ><b>{{ credit }}</b></router-link
          >
        </li>
        <hr class="rm-hr"/>
        <li>
          <router-link to="/my-profile" class="sidebar-user-option"
          ><i class="fa fa-user"></i>{{ $t("menu.myProfile") }}
          </router-link
          >
        </li>
        <li v-if="isAdmin">
          <router-link to="/status" class="sidebar-user-option"
          ><i class="fa fa-battery-full"></i>{{ $t("admin.status") }}
          </router-link
          >
        </li>
        <li v-if="isAdmin">
          <router-link to="/users" class="sidebar-user-option"
          ><i class="fa fa-user-friends"></i>{{ $t("admin.users") }}
          </router-link
          >
        </li>
        <li>
          <router-link to="/logout" class="sidebar-user-option"
          ><i class="fa fa-sign-out-alt"></i>{{ $t("menu.logOut") }}
          </router-link
          >
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import {isUserAdmin, userHasRequiredRoles} from "@/_helpers";

export default {
  name: "SidebarNavProfile",
  props: ["account"],
  data() {
    return {
      stat: "",
      pageOptions: PageOptions,
    };
  },
  computed: {
    isAdmin() {
      return isUserAdmin();
    },
    isMentor() {
      return userHasRequiredRoles(["mentor"])
    },
    credit() {
      return this.account.user.credit_amount;
    },
    caretClass() {
      let baseClass = "fa pull-right";
      if (this.stat === "expand") {
        return baseClass + " fa-caret-down";
      }
      return baseClass + " fa-caret-right";
    }
  },
  methods: {
    expand: function () {
      this.stat = this.stat === "expand" ? "collapse" : "expand";
    },
  },
};
</script>
