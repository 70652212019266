import {config} from "@/config/config";
import {authHeader} from "@/_helpers";
import {fetcher} from "@/_helpers/api";
import {getLocalStorageUserItemName} from "@/_helpers/domain_helper";

export const userService = {
    loginGoogleOauth2,
    logout,
    getAll,
    get,
    update,
    cleanData,
    delete: _delete,
};

function loginGoogleOauth2(id_token) {
    const requestOptions = {
        method: config.aaa.login.googleOauth2.method,
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({id_token}),
    };

    return fetcher(
        `${config.apiUrl}${config.aaa.login.googleOauth2.url}`,
        requestOptions
    )
        .then(handleResponse)
        .then((user) => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(getLocalStorageUserItemName(), JSON.stringify(user));
            } else {
                console.log("User token NOT saved!");
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(getLocalStorageUserItemName());
}

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetcher(`${config.apiUrl}users`, requestOptions).then(handleResponse);
}

function get() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetcher(`${config.apiUrl}users`, requestOptions).then(
        handleResponse
    );
}

function update(user) {
    const requestOptions = {
        method: "PUT",
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify(user),
    };

    return fetcher(`${config.apiUrl}users`, requestOptions).then(
        handleResponse
    );
}

function cleanData() {
    const requestOptions = {
        method: "PUT",
        headers: {...authHeader(), "Content-Type": "application/json"},
    };

    return fetcher(`${config.apiUrl}users/clean`, requestOptions).then(
        handleResponse
    );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
    };

    return fetcher(`${config.apiUrl}users/${id}`, requestOptions).then(
        handleResponse
    );
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
