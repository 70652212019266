import {config} from "@/config/config";
import {snakeCase} from "lodash";


export function getAllGames() {
    let allGames = {...config.games.props};
    const gameKeys = Object.keys(allGames);

    gameKeys.forEach(function (game) {
        if (!allGames[game].active) {
            delete allGames[game];
        }
    });

    return allGames;
}

export function getEntertainmentGames() {
    let allGames = {...config.games.props};
    const gameKeys = Object.keys(allGames);

    gameKeys.forEach(function (game) {
        if (!allGames[game].active || allGames[game].type !== "entertainment") {
            delete allGames[game];
        }
    });

    return allGames;
}

export function getEducationalGames() {
    let allGames = {...config.games.props};
    const gameKeys = Object.keys(allGames);

    gameKeys.forEach(function (game) {
        if (!allGames[game].active || allGames[game].type !== "education") {
            delete allGames[game];
        }
    });

    return allGames;
}

export function getEducationalArithmeticOperationsGames() {
    let allGames = {...config.games.props};
    const gameKeys = Object.keys(allGames);

    gameKeys.forEach(function (game) {
        if (!allGames[game].active || allGames[game].type !== "education" || (allGames[game].type === "education" && allGames[game].subType !== "arithmeticOperations")) {
            delete allGames[game];
        }
    });

    return allGames;
}

export function getEducationalNumeralSystemsGames() {
    let allGames = {...config.games.props};
    const gameKeys = Object.keys(allGames);

    gameKeys.forEach(function (game) {
        if (!allGames[game].active || allGames[game].type !== "education" || (allGames[game].type === "education" && allGames[game].subType !== "numeralSystems")) {
            delete allGames[game];
        }
    });

    return allGames;
}

export function getEducationalMeasurmentGames() {
    let allGames = {...config.games.props};
    const gameKeys = Object.keys(allGames);

    gameKeys.forEach(function (game) {
        if (!allGames[game].active || allGames[game].type !== "education" || (allGames[game].type === "education" && allGames[game].subType !== "measurements")) {
            delete allGames[game];
        }
    });

    return allGames;
}

export function getEducationalEquationsGames() {
    let allGames = {...config.games.props};
    const gameKeys = Object.keys(allGames);

    gameKeys.forEach(function (game) {
        if (!allGames[game].active || allGames[game].type !== "education" || (allGames[game].type === "education" && allGames[game].subType !== "equations")) {
            delete allGames[game];
        }
    });

    return allGames;
}

export function getEducationalOtherGames() {
    let allGames = {...config.games.props};
    const gameKeys = Object.keys(allGames);

    gameKeys.forEach(function (game) {
        if (!allGames[game].active || allGames[game].type !== "education" || (allGames[game].type === "education" && allGames[game].subType !== "other")) {
            delete allGames[game];
        }
    });

    return allGames;
}

export function isEducationMathGame(game) {
    const educationMathGames = getEducationalGames();
    const gameKeys = Object.keys(educationMathGames);
    return gameKeys.includes(game);
}

export function getPeriodicGameUrl(game, period) {
    // Get URL for getting specific periodic game data
    const scGame = snakeCase(game);
    const scPeriod = snakeCase(period);
    return `${config.apiUrl}games/${scGame}/periodic/${scPeriod}`;
}

export function getPeriodicGameMethod() {
    // Get HTTP method for getting periodic game data
    return "GET";
}

export function getGameUrl(game, gridId) {
    // Get URL for getting specific game data
    const scGame = snakeCase(game);
    return `${config.apiUrl}games/${scGame}/${gridId}`;
}

export function getGameMethod() {
    // Get HTTP method for getting game data
    return "GET";
}

export function getUpdateGameUrl(game) {
    // Get URL for updating specific game data
    const scGame = snakeCase(game);
    return `${config.apiUrl}games/${scGame}/update`;
}

export function getUpdateGameMethod() {
    // Get HTTP method for updating specific game data
    return "POST";
}

export function getUpdatePeriodicGameUrl(game, period) {
    // Get URL for updating specific periodic game data
    const scGame = snakeCase(game);
    const scPeriod = snakeCase(period);
    return `${config.apiUrl}games/user_${scGame}_${scPeriod}`;
}

export function getUpdatePeriodicGameMethod() {
    // Get HTTP method for updating specific periodic game data
    return "POST";
}

export function getGameInfoUrl(game) {
    // Get URL for getting game info (prices)
    const scGame = snakeCase(game);
    return `${config.apiUrl}games/info/${scGame}`;
}

export function getGameSettingsUrl(game) {
    // Get URL for getting game info (prices)
    const scGame = snakeCase(game);
    return `${config.apiUrl}games/settings/${scGame}`;
}

export function getGameInfoMethod() {
    // Get HTTP method for getting game info (prices)
    return "GET";
}

export function getGameSettingsMethod() {
    // Get HTTP method for getting game settings
    return "GET";
}

export function getGameCreateMethod() {
    // Get HTTP method for creating new game for user
    return "POST";
}

export function getGameCreateUrl(game) {
    // Get URL for getting game info (prices)
    const scGame = snakeCase(game);
    return `${config.apiUrl}games/${scGame}/create`;
}

export function getCarrotsString(gamePrice) {
    // Word should represent statement: Ta igra zahteva X <korencek_work>
    if (gamePrice === 0.0) {
        return "games.dispatch.carrots.zero";
    }
    if (gamePrice === 1.0) {
        return "games.dispatch.carrots.one";
    }
    if (gamePrice === 2.0) {
        return "games.dispatch.carrots.two";
    }
    if (gamePrice === 3.0 || gamePrice === 4.0) {
        return "games.dispatch.carrots.threeAndFour";
    }
    if (gamePrice >= 5.0 && gamePrice % 1 === 0) {
        return "games.dispatch.carrots.fiveAndMore";
    }

    // gamePrice is certainly not integer from here on
    return "games.dispatch.carrots.else";
}

export function getCarrotsStateString(
    credit,
    currentGamePrice,
    regularGamePrice,
    vueInstance,
    isAssignment = false,
) {
    if (currentGamePrice === null || regularGamePrice === null) {
        return "";
    }

    const carrotKey = getCarrotsString(currentGamePrice);
    const carrotStr = vueInstance.$t(carrotKey);

    if (currentGamePrice === 0.0) {
        // Game is currently free, not always!
        if (regularGamePrice > 0.0) {
            if (isAssignment) {
                return vueInstance.$t("games.dispatch.prices.currentlyFreeAssignment");
            }
            return vueInstance.$t("games.dispatch.prices.currentlyFree");
        }
        // Game is always free!
        if(isAssignment) {
            return vueInstance.$t("games.dispatch.prices.alwaysFreeAssignment");
        }
        return vueInstance.$t("games.dispatch.prices.alwaysFree");
    }

    // From here on game is not free
    if (credit < currentGamePrice) {
        let baseStr = vueInstance.$t("games.dispatch.prices.notEnough") + " " + vueInstance.$t("games.dispatch.prices.gameRequires").replace("{0}", "<b>{0}</b>").replace("{0}", currentGamePrice).replace("{1}", carrotStr);
        if (isAssignment) {
            baseStr = vueInstance.$t("games.dispatch.prices.notEnough") + " " + vueInstance.$t("games.dispatch.prices.assignmentRequires").replace("{0}", "<b>{0}</b>").replace("{0}", currentGamePrice).replace("{1}", carrotStr);
        }
        const creditCarrotsKey = getCarrotsString(credit);
        const creditCarrotsStr = vueInstance.$t(creditCarrotsKey);
        if (credit === 0) {
            return baseStr + " " + vueInstance.$t("games.dispatch.prices.youHaveNoCarrots")
        }
        return baseStr + " " + vueInstance.$t("games.dispatch.prices.youHaveSomeCarrots").replace("{0}", "<b>{0}</b>").replace("{0}", credit).replace("{1}", creditCarrotsStr);
    }
    if(isAssignment) {
        return vueInstance.$t("games.dispatch.prices.assignmentRequires").replace("{0}", "<b>{0}</b>").replace("{0}", currentGamePrice).replace("{1}", carrotStr);
    }
    return vueInstance.$t("games.dispatch.prices.gameRequires").replace("{0}", "<b>{0}</b>").replace("{0}", currentGamePrice).replace("{1}", carrotStr);
}

export function getGameSettingName(game, diffObj, vueInstance = null) {
    if (game === config.games.props.sudoku.id) {
        return vueInstance.$t("games.games.sudoku.difficulties." + diffObj.id);
    } else if (game === config.games.props.minesweeper.id) {
        let config = diffObj.id.split("_");
        let baseStr = vueInstance.$t("games.games.minesweeper.difficulties.numMines");
        return baseStr.replace("{0}", config[0]).replace("{1}", config[1]).replace("{2}", config[2]);
    } else if (game === config.games.props.memory.id) {
        const config = diffObj.id.split("_");
        let baseStr = vueInstance.$t("games.games.memory.difficulties.numberOfOccurrences");
        return baseStr.replace("{0}", config[0]).replace("{1}", config[1]).replace("{2}", config[2]);
    } else if (game === config.games.props.caesarCypher.id) {
        let config = diffObj.id.split("_");
        let baseStr = vueInstance.$t("games.games.caesarCypher.difficulties.fromToLetters");
        return baseStr.replace("{0}", config[0]).replace("{1}", config[1]);
    } else if (game === config.games.props.wordSearch.id) {
        return vueInstance.$t("games.games.wordSearch.difficulties." + diffObj.id);
    } else if (game === config.games.props.mathCrossword.id) {
        let baseStr = vueInstance.$t("games.games.mathCrossword.difficulties.numbersUpTo");
        return baseStr.replace("{0}", diffObj.config.max_number);
    } else if (game === config.games.props.simpleMaze.id) {
        let config = diffObj.id.split("_");
        return `${config[0]} x ${config[1]}`;
    } else if (game === config.games.props.simpleMath.id) {
        const config = diffObj.id.split("_");
        let baseStr = vueInstance.$t("games.games.simpleMath.difficulties.numsBetween");
        return baseStr.replace("{0}", config[0]).replace("{1}", config[1]).replace("{2}", config[2]).replace("{3}", config[3]).replace("{4}", config[4]);
    } else if (game === config.games.props.meme.id) {
        const config = diffObj.id.split("_");
        let baseStr = vueInstance.$t("games.games.meme.difficulties.settingName");
        return baseStr.replace("{0}", config[1]).replace("{1}", config[2]).replace("{2}", config[0]);
    }
    return "";
}