import { fetcher, handleResponse } from "@/_helpers/api";
import { isUserLoggedIn } from "@/_helpers";
import {
  getGameCreateMethod,
  getGameCreateUrl,
  getGameInfoMethod,
  getGameInfoUrl,
  getGameMethod,
  getGameUrl,
  getPeriodicGameMethod,
  getPeriodicGameUrl,
  getUpdatePeriodicGameMethod,
  getUpdatePeriodicGameUrl,
  getUpdateGameMethod,
  getUpdateGameUrl,
  getGameSettingsMethod,
  getGameSettingsUrl,
} from "@/_helpers/games_helper";

export const gamesService = {
  get_periodic,
  update_periodic,
  get_info,
  get_settings,
  create_game,
  get,
  update,
};

function get(game, gridId) {
  let requestOptions = {
    method: getGameMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getGameUrl(game, gridId), requestOptions).then(handleResponse);
}

function get_periodic(game, period) {
  let requestOptions = {
    method: getPeriodicGameMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getPeriodicGameUrl(game, period), requestOptions).then(
    handleResponse
  );
}

function update(data, game) {
  if (!isUserLoggedIn()) {
    return null;
  }

  let requestOptions = {
    method: getUpdateGameMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetcher(getUpdateGameUrl(game), requestOptions).then(handleResponse);
}

function update_periodic(data, game, period) {
  if (!isUserLoggedIn()) {
    return null;
  }

  let requestOptions = {
    method: getUpdatePeriodicGameMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetcher(getUpdatePeriodicGameUrl(game, period), requestOptions).then(
    handleResponse
  );
}

function get_info(game) {
  let requestOptions = {
    method: getGameInfoMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getGameInfoUrl(game), requestOptions).then(handleResponse);
}

function get_settings(game) {
  let requestOptions = {
    method: getGameSettingsMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getGameSettingsUrl(game), requestOptions).then(handleResponse);
}

function create_game(game, gameData) {
  let requestOptions = {
    method: getGameCreateMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(gameData),
  };

  return fetcher(getGameCreateUrl(game), requestOptions).then(handleResponse);
}
