<script>
import Vue from "vue";
import { config } from "@/config/config";
import router from "../../config/PageRoutes";
import { find, isEqual } from "lodash";
import {
  setGrids,
  setEmptyGrids,
  setState,
} from "@/_helpers/games/word-search-helper";
import { gamesService } from "@/_services";
import { handleError } from "@/_helpers/api";

const wordSearchStore = {
  namespaced: true,
  state: {
    gridId: null,
    grid: null,
    words: null,
    wordSize: 0,
    completed: false,
    stateShown: false,
    solutionAchieved: false,
    hasWrongValues: false,
    allFilledUp: false,
    step: null,
    selected: null,
    initialState: false,
    period: null,
    selectedWord: null,
    selectedCoords: [],
    wordFound: false,
  },
  mutations: {
    async initGrid(state, gridData) {
      const period = gridData.period;
      const gridId = gridData.gridId;

      let emptyGrids = setEmptyGrids(6, 6, 4);
      state.completed = false;
      setState(state, emptyGrids);

      state.period = period;
      state.gridId = gridId;

      state.initialState = false;
      state.selectedWord = null;
      state.selectedCoords = [];

      if (period !== "") {
        gamesService
          .get_periodic(config.games.props.wordSearch.id, period)
          .then((data) => {
            let grids = setGrids(data);
            state.completed = data.completed;
            setState(state, grids);

            processResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      } else if (gridId !== "") {
        gamesService
          .get(config.games.props.wordSearch.id, gridId)
          .then((data) => {
            let grids = setGrids(data);
            state.completed = data.completed;
            setState(state, grids);

            processResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      }
    },
    // eslint-disable-next-line no-unused-vars
    resetGrid(state, value) {
      for (let i = 0; i < state.grid.length; i++) {
        for (let x = 0; x < state.grid[i].length; x++) {
          state.grid[i][x].marked = false;
          state.grid[i][x].selected = false;
          Vue.set(state.grid, i, state.grid[i]);
        }
      }
      for (let i = 0; i < state.words.length; i++) {
        for (let j = 0; j < state.words[i].length; j++) {
          state.words[i][j].f = false;
        }
      }

      state.selectedWord = null;
      state.selectedCoords = [];

      processNumberSearchResult(state, true);
    },
    // Function selects or deselects certain field
    setSelected(state, pos) {
      if (state.solutionAchieved) return;

      if (state.selectedWord === null) return;

      setSelectedPosition(state, pos);
      if (state.wordFound) processNumberSearchResult(state, false);
      state.wordFound = false;
    },
    // Function selects word that we will mark
    selectWord(state, pos) {
      if (state.solutionAchieved) return;

      // Do nothing if this word is already found
      if (state.words[pos.x][pos.y].f) return;

      let selectWord = true;

      // If any word is already selected
      if (state.selectedWord !== null) {
        // If we click on the same word as is already selected
        if (state.selectedWord.x === pos.x && state.selectedWord.y === pos.y) {
          // Deselect it
          state.selectedWord = null;
          // Do not select same word again
          selectWord = false;
        }

        // Unmark all selected numbers
        unmarkAllSelectedNumbers(state);
      }

      if (selectWord) {
        state.selectedWord = pos;
      }
    },
  },
  actions: {},
};

function unmarkAllSelectedNumbers(state) {
  for (let i = 0; i < state.grid.length; i++) {
    for (let j = 0; j < state.grid[i].length; j++) {
      state.grid[i][j].selected = false;
    }
  }
}

function processNumberSearchResult(state, initialState = false) {
  // Do not invoke APIs if resetting already reset state
  if (initialState && state.initialState) {
    return;
  }

  state.initialState = initialState;

  processResult(state);

  if (!state.completed) {
    updateUserNumberSearchInfo(state);
    if (state.solutionAchieved) {
      state.completed = true;
    }
  }
}

function updateUserNumberSearchInfo(state) {
  let foundWords = [];
  for (let i = 0; i < state.words.length; i++) {
    const wFound = state.words[i].filter((x) => x.f).map((x) => x.w);
    foundWords.push(...wFound);
  }

  let data = {
    gridId: state.gridId,
    foundWords: foundWords,
    completed: state.solutionAchieved,
  };

  let retObj = null;
  if (state.period === "") {
    retObj = gamesService.update(data, config.games.props.wordSearch.id);
  } else {
    retObj = gamesService.update_periodic(
      data,
      config.games.props.wordSearch.id,
      state.period
    );
  }

  if (retObj !== null) {
    retObj.catch((error) => handleError(error, router));
  }
}

function markWordFound(state) {
  for (let i = 0; i < state.selectedCoords.length; i++) {
    const x = state.selectedCoords[i][0];
    const y = state.selectedCoords[i][1];

    state.grid[x][y].marked = true;
    state.grid[x][y].selected = false;
  }

  state.selectedCoords = [];

  const word_x = state.selectedWord.x;
  const word_y = state.selectedWord.y;

  state.words[word_x][word_y].f = true;
  state.wordFound = true;
  state.selectedWord = null;
}

function setSelectedPosition(state, pos) {
  let isCurrentSelected = find(
    state.selectedCoords,
    (el) => el[0] === pos.x && el[1] === pos.y
  );
  if (isCurrentSelected !== undefined) {
    state.grid[pos.x][pos.y].selected = false;

    const elIndex = state.selectedCoords.findIndex(
      (el) => el[0] === pos.x && el[1] === pos.y
    );
    state.selectedCoords.splice(elIndex, 1);

    Vue.set(state.grid, pos.x, state.grid[pos.x]);
    return;
  }

  // This position is not selected yet
  if (state.selectedCoords.length === state.wordSize) {
    unmarkAllSelectedNumbers(state);
    state.selectedCoords = [];
  }

  state.grid[pos.x][pos.y].selected = true;
  state.selectedCoords.push([pos.x, pos.y]);

  // Check if entire word was selected
  if (state.selectedCoords.length === state.wordSize) {
    let wordToFind = state.words[state.selectedWord.x][state.selectedWord.y].c;
    let selectedCoords = state.selectedCoords;

    wordToFind.sort();
    selectedCoords.sort();

    if (isEqual(wordToFind, selectedCoords)) {
      markWordFound(state);
    }
  }

  Vue.set(state.grid, pos.x, state.grid[pos.x]);
}

function processResult(state) {
  state.allFilledUp = areAllWordsFound(state);
  state.solutionAchieved = state.allFilledUp;
}

function areAllWordsFound(state) {
  for (let i = 0; i < state.words.length; i++) {
    for (let x = 0; x < state.words[i].length; x++) {
      if (!state.words[i][x].f) return false;
    }
  }
  return true;
}

export default wordSearchStore;
</script>
