<template>
  <div
      class="fade page-sidebar-fixed page-header-fixed show page-container"
      v-if="!pageOptions.pageEmpty"
      v-bind:class="{
      'page-sidebar-minified': pageOptions.pageSidebarMinified,
      'page-content-full-height': pageOptions.pageContentFullHeight,
      'page-without-sidebar': pageOptions.pageWithoutSidebar,
      'page-with-right-sidebar': pageOptions.pageWithRightSidebar,
      'page-with-two-sidebar': pageOptions.pageWithTwoSidebar,
      'page-with-wide-sidebar': pageOptions.pageWithWideSidebar,
      'page-with-light-sidebar': pageOptions.pageWithLightSidebar,
      'page-with-top-menu': pageOptions.pageWithTopMenu,
      'page-sidebar-toggled': pageOptions.pageMobileSidebarToggled,
      'page-right-sidebar-toggled':
        pageOptions.pageMobileRightSidebarToggled ||
        pageOptions.pageRightSidebarToggled,
      'page-right-sidebar-collapsed': pageOptions.pageRightSidebarCollapsed,
      'has-scroll': pageOptions.pageBodyScrollTop,
    }"
  >
    <Header/>
    <Sidebar v-if="!pageOptions.pageWithoutSidebar"/>
    <div
        id="content"
        class="content"
        v-bind:class="{
        'content-full-width': pageOptions.pageContentFullWidth,
        'content-inverse-mode': pageOptions.pageContentInverseMode,
      }"
    >
      <router-view></router-view>
      <vue-ins-progress-bar></vue-ins-progress-bar>
    </div>
  </div>
  <div v-else>
    <router-view></router-view>
    <vue-ins-progress-bar></vue-ins-progress-bar>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import Sidebar from "./components/sidebar/Sidebar.vue";
import Header from "./components/header/Header.vue";
import PageOptions from "./config/PageOptions.vue";

export default {
  name: "app",
  components: {
    Sidebar,
    Header,
  },
  data() {
    return {
      pageOptions: PageOptions,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    handleScroll: function () {
      PageOptions.pageBodyScrollTop = window.scrollY;
    },
    ...mapActions({
      clearAlert: "alert/clear",
    }),
  },
  mounted() {
    this.$insProgress.finish();
  },
  created() {
    PageOptions.pageBodyScrollTop = window.scrollY;

    window.addEventListener("scroll", this.handleScroll);

    this.$insProgress.start();

    this.$router.beforeEach((to, from, next) => {
      this.$insProgress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$insProgress.finish();
    });
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      // clear alert on location change
      this.clearAlert();
    },
  },
};
</script>
