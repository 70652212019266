<script>
import Vue from "vue";
import { config } from "@/config/config";
import router from "../../config/PageRoutes";
import {
  findNextNotLocked,
  processResult,
  setSelectedPosition,
  toggleWrongValues,
  resetGridData, commonStoreState, setInitialState, updateGameData,
} from "@/config/stores/common";
import {
  setEmptyGrids,
  setGrids,
  setState,
} from "@/_helpers/games/sudoku-helper";
import { gamesService } from "@/_services";
import { handleError } from "@/_helpers/api";

const sudokuStore = {
  namespaced: true,
  state: {
    ...commonStoreState,
    // game specific vars
    origString: null,
    solution: null,
    solutionGrid: null,
    difficulty: "easy",
    selected: null,
  },
  mutations: {
    async initGrid(state, gridData) {
      const period = gridData.period;
      const gridId = gridData.gridId;

      const emptyGrids = setEmptyGrids(9, 9);
      setState(state, emptyGrids);

      setInitialState(state);
      state.period = period;
      state.gridId = gridId;

      if (period !== "") {
        gamesService
          .get_periodic(config.games.props.sudoku.id, period)
          .then((data) => {
            const gridData = setGrids(data);
            setState(state, gridData);

            processResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      } else if (gridId !== "") {
        gamesService
          .get(config.games.props.sudoku.id, gridId)
          .then((data) => {
            const gridData = setGrids(data);
            setState(state, gridData);

            processResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      }
    },
    // eslint-disable-next-line no-unused-vars
    resetGrid(state, value) {
      resetGridData(state);
      processSudokuResult(state, true);
    },
    showCurrentState(state) {
      toggleWrongValues(state);
    },
    setDifficulty(state, value) {
      // TODO: Verify value
      state.difficulty = value;
    },
    setValue(state, num) {
      if (state.solutionAchieved) return;

      // Do not set value is nothing is selected
      if (!state.selected) return;

      // Make sure that user entered integer
      let parsedValue = parseInt(num);
      if (isNaN(parsedValue) || !Number.isInteger(parsedValue)) {
        num = "";
      }

      // Do not change value if value is the same
      if (state.grid[state.selected.x][state.selected.y].value === num) return;

      state.grid[state.selected.x][state.selected.y].value = num;
      Vue.set(state.grid, state.selected.x, state.grid[state.selected.x]);

      processSudokuResult(state);
    },
    moveInDirection(state, direction) {
      if (state.solutionAchieved) return;

      let new_pos = findNextNotLocked(state, direction);
      if (new_pos.x !== -1 && new_pos.y !== -1) {
        setSelectedPosition(state, new_pos);
      }
    },
    // Function selects or deselects certain field
    setSelected(state, pos) {
      if (state.solutionAchieved) return;

      setSelectedPosition(state, pos);
    },
  },
  actions: {},
};

function processSudokuResult(state, initialState = false) {
  // Do not invoke APIs if resetting already reset state
  if (initialState && state.initialState) {
    return;
  }
  state.initialState = initialState;

  processResult(state);

  if (!state.completed) {
    updateUserSudokuInfo(state);
    if (state.solutionAchieved) {
      state.completed = true;
    }
  }
}

function updateUserSudokuInfo(state) {
  let gridString = "";

  for (let i = 0; i < state.grid.length; i++) {
    for (let x = 0; x < state.grid[i].length; x++) {
      if (state.grid[i][x].value === "") {
        gridString = gridString.concat("0");
      } else {
        gridString = gridString.concat(state.grid[i][x].value);
      }
    }
  }

  let data = {
    gridId: state.gridId,
    gridString: gridString,
    completed: state.solutionAchieved,
  };

  updateGameData(config.games.props.sudoku.id, data, state.period)
}

export default sudokuStore;
</script>
