<script>
import {
  setEmptyGrids,
  setState,
  setGrids,
} from "@/_helpers/games/simple-math-helper";
import {gamesService} from "@/_services";
import {config} from "@/config/config";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import Vue from "vue";
import {isEqual} from "lodash";
import {commonStoreState, setInitialState, updateGameData} from "@/config/stores/common";

const simpleMathStore = {
  namespaced: true,
  state: {
    ...commonStoreState,
    // game specific vars
    gridSymbols: null,
    words: null,
    foundWords: null,
    wordSize: 0,
    step: null,
    selected: null,
    selectedWord: null,
    selectedCoords: [],
    maxSelectedCoords: 0,
    wordFound: false,
    gameType: null,
    gameNumbers: null,
  },
  mutations: {
    async initGrid(state, gridData) {
      const period = gridData.period;
      const gridId = gridData.gridId;

      setInitialState(state);
      state.gridId = gridId;
      state.period = period;

      let emptyGrids = setEmptyGrids(4, 4);
      state.grid = emptyGrids.grid;
      state.gridSymbols = emptyGrids.gridSymbols;
      state.gameType = emptyGrids.gameType;
      state.gameNumbers = emptyGrids.gameNumbers;
      state.selectedCoords = [];

      if (period !== "") {
        gamesService
            .get_periodic(config.games.props.simpleMath.id, period)
            .then((data) => {
              let grids = setGrids(data);
              state.completed = data.completed;
              setState(state, grids);

              processResult(state);
            })
            .catch((error) => handleError(error, router, this._vm));
      } else if (gridId !== "") {
        gamesService
            .get(config.games.props.simpleMath.id, gridId)
            .then((data) => {
              let grids = setGrids(data);
              state.completed = data.completed;
              setState(state, grids);

              processResult(state);
            })
            .catch((error) => handleError(error, router, this._vm));
      }
    },
    resetGrid(...[state,]) {
      for (let i = 0; i < state.grid.length; i++) {
        for (let j = 0; j < state.grid[i].length; j++) {
          state.grid[i][j].selected = false;
          state.grid[i][j].correct_value = false;
          state.grid[i][j].wrong_value = false;

          Vue.set(state.grid, i, state.grid[i]);
        }
      }

      state.selectedCoords = [];

      processSimpleMathResult(state, true);
    },
    // Function selects or deselects certain field
    setSelected(state, pos) {
      if (state.solutionAchieved) {
        return;
      }

      const x = parseInt(pos.x);
      const y = parseInt(pos.y);

      if (!state.grid[x][y].selected) {
        selectField(state, x, y);
      } else {
        clearSelectedField(state, x, y);
      }

      processSimpleMathResult(state);
    },
  },
};

function processResult(state) {
  state.allFilledUp = areAllValuesFound(state);
  state.solutionAchieved = state.allFilledUp;
}

function areAllValuesFound(state) {
  state.gridSymbols.sort();
  state.selectedCoords.sort();

  return isEqual(state.gridSymbols, state.selectedCoords);
}

function selectField(state, x, y) {
  state.grid[x][y].selected = true;
  state.selectedCoords.push([x, y]);
  Vue.set(state.grid, x, state.grid[x]);
}

function clearSelectedField(state, x, y) {
  state.grid[x][y].selected = false;
  for (let i = 0; i < state.selectedCoords.length; i++) {
    const el = state.selectedCoords[i];
    if (el[0] === x && el[1] === y) {
      state.selectedCoords.splice(i, 1);
      break;
    }

    Vue.set(state.grid, el[0], state.grid[el[0]]);
  }
}

function processSimpleMathResult(state, initialState = false) {
  // Do not invoke APIs if resetting already reset state
  if (initialState && state.initialState) {
    return;
  }

  state.initialState = initialState;

  processResult(state);

  if (!state.completed) {
    updateSimpleMathInfo(state);
    if (state.solutionAchieved) {
      state.completed = true;
    }
  }
}

function updateSimpleMathInfo(state) {
  let data = {
    gridId: state.gridId,
    gameType: state.gameType,
    foundWords: state.selectedCoords,
    completed: state.solutionAchieved,
  };

  updateGameData(config.games.props.simpleMath.id, data, state.period)
}

export default simpleMathStore;
</script>
