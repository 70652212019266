import Vue from "vue";
import {config} from "@/config/config";
import {gamesService} from "@/_services";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import {assignmentService} from "@/_services/assignment.service";

export const commonStoreState = {
    gridId: null,
    grid: null,
    period: null,

    completed: false,
    solutionAchieved: false,
    allFilledUp: false,
    initialState: false,
    hasWrongValues: false,
    stateShown: false,
}

export function setInitialState(state) {
    state.completed = false;
    state.solutionAchieved = false;
    state.allFilledUp = false;
    state.initialState = false;
    state.hasWrongValues = false;
    state.stateShown = false;
}

export function handleKeyPress(e, validValues, storeName, storeObj){
  const upperCaseKey = e.key.toUpperCase();
  if (validValues.includes(upperCaseKey) === true) {
    storeObj.commit(storeName + "/setValue", upperCaseKey);
  } else if (upperCaseKey === "BACKSPACE") {
    storeObj.commit(storeName + "/setValue", "");
  } else if (upperCaseKey === "ARROWUP") {
    storeObj.commit(storeName + "/moveInDirection", 0);
  } else if (upperCaseKey === "ARROWDOWN") {
    storeObj.commit(storeName + "/moveInDirection", 1);
  } else if (upperCaseKey === "ARROWLEFT") {
    storeObj.commit(storeName + "/moveInDirection", 2);
  } else if (upperCaseKey === "ARROWRIGHT") {
    storeObj.commit(storeName + "/moveInDirection", 3);
  }
}

export function updateGameData(game, data, period) {
  let retObj;
  if (data.game === "assignment") {
    retObj = assignmentService.updateMyAssignment(data.gameId, data);
  }
  else if (period === "") {
    retObj = gamesService.update(data, game);
  } else {
    retObj = gamesService.update_periodic(data, game, period);
  }

  if (retObj !== null) {
    retObj.catch((error) => handleError(error, router));
  }

  return retObj;
}

export function setSelectedPosition(state, pos) {
    if (state.grid[pos.x][pos.y].locked) return;

    let selectCell = true;

    // If any cell is already selected
    if (state.selected !== null) {
        const selectedRowId = state.selected.x;
        const selectedColId = state.selected.y;

        // Deselect currently selected cell
        state.grid[selectedRowId][selectedColId].selected = false;
        state.selected = null;
        Vue.set(state.grid, pos.x, state.grid[pos.x]);

        // Do not select cell if selected cell was current cell
        if (selectedRowId === pos.x && selectedColId === pos.y) {
            selectCell = false;
        }
    }

    if (selectCell) {
        state.grid[pos.x][pos.y].selected = true;
        state.selected = pos;
        Vue.set(state.grid, pos.x, state.grid[pos.x]);
    }
}

export function setWrongValues(state, flag) {
    state.stateShown = flag;
    for (let i = 0; i < state.grid.length; i++) {
        for (let x = 0; x < state.grid[i].length; x++) {
            if (flag === false) {
                state.grid[i][x].wrong_value = false;
                state.grid[i][x].correct_value = false;
            } else {
                if ("locked" in state.grid[i][x] && state.grid[i][x].locked) continue;

                if (state.grid[i][x].value !== "") {
                    if (state.grid[i][x].value !== state.grid[i][x].solution) {
                        state.grid[i][x].wrong_value = true;
                        state.grid[i][x].correct_value = false;
                    } else {
                        state.grid[i][x].wrong_value = false;
                        state.grid[i][x].correct_value = true;
                    }
                } else {
                    state.grid[i][x].wrong_value = false;
                    state.grid[i][x].correct_value = false;
                }
            }
            Vue.set(state.grid, i, state.grid[i]);
        }
    }
}

export function toggleWrongValues(state) {
    if (state.stateShown) {
        setWrongValues(state, false);
    } else {
        setWrongValues(state, true);
    }
}

export function areAllValuesFilledUp(state) {
    let anyFieldToBeFilled = false;

    for (let i = 0; i < state.grid.length; i++) {
        for (let x = 0; x < state.grid[i].length; x++) {
            if ("locked" in state.grid[i][x] && state.grid[i][x].locked) continue;

            anyFieldToBeFilled = true;

            if (state.grid[i][x].value === "" && state.grid[i][x].solution === "")
                continue;

            if (
                state.grid[i][x].value === "" ||
                state.grid[i][x].value === false ||
                state.grid[i][x].value === null
            )
                return false;
        }
    }
    return anyFieldToBeFilled;
}

export function hasWrongValues(state) {
    for (let i = 0; i < state.grid.length; i++) {
        for (let x = 0; x < state.grid[i].length; x++) {
            if ("locked" in state.grid[i][x] && state.grid[i][x].locked) continue;

            if (state.grid[i][x].value !== state.grid[i][x].solution) return true;
        }
    }
    return false;
}

export function processResult(state) {
    setWrongValues(state, false);
    state.allFilledUp = areAllValuesFilledUp(state);
    state.solutionAchieved = false;
    state.hasWrongValues = hasWrongValues(state);

    if (state.allFilledUp && !state.hasWrongValues) {
        setWrongValues(state, false);
        state.solutionAchieved = true;
    }
}

// Function finds next not locked cell in certain direction
// Param:
//    - direction:
//        - 0: UP
//        - 1: DOWN
//        - 2: LEFT
//        - 3: RIGHT
export function findNextNotLocked(state, direction) {
    let selected_row = 0;
    let selected_col = 0;

    let new_pos = {
        x: -1,
        y: -1,
    };

    if (state.selected === null) {
        return new_pos;
    }

    if (state.selected !== null) {
        selected_row = state.selected.x;
        selected_col = state.selected.y;
    }

    if (direction === 0) {
        let current_row = selected_row;
        while (current_row > 0) {
            current_row--;
            let field = state.grid[current_row][selected_col];
            if (field.locked === false) {
                new_pos.x = current_row;
                new_pos.y = selected_col;
                break;
            }
        }
    } else if (direction === 1) {
        let current_row = selected_row;
        while (current_row < state.grid.length - 1) {
            current_row++;
            let field = state.grid[current_row][selected_col];
            if (field.locked === false) {
                new_pos.x = current_row;
                new_pos.y = selected_col;
                break;
            }
        }
    } else if (direction === 2) {
        let current_col = selected_col;
        while (current_col > 0) {
            current_col--;
            let field = state.grid[selected_row][current_col];
            if (field.locked === false) {
                new_pos.x = selected_row;
                new_pos.y = current_col;
                break;
            }
        }
    } else if (direction === 3) {
        let current_col = selected_col;
        while (current_col < state.grid[0].length - 1) {
            current_col++;
            let field = state.grid[selected_row][current_col];
            if (field.locked === false) {
                new_pos.x = selected_row;
                new_pos.y = current_col;
                break;
            }
        }
    }

    return new_pos;
}

export function getGridCellClass(grid, x, y, solutionAchieved, baseClass) {
    const isLocked = "locked" in grid[x][y] && grid[x][y].locked;
    const isMarked = "marked" in grid[x][y] && grid[x][y].marked;
    const isSelected = "selected" in grid[x][y] && grid[x][y].selected;
    const isWrongValue = "wrong_value" in grid[x][y] && grid[x][y].wrong_value;
    const isCorrectValue =
        "correct_value" in grid[x][y] && grid[x][y].correct_value;

    // If cell is locked it means that value cannot be changes
    // It doesn't matter if solution is found, cell will always be grey
    if (isLocked) {
        return baseClass + " rm-locked";
    }

    // Did we and the game and we found correct solution
    if (solutionAchieved) {
        // "marked" is special case for WordSearch game
        if ("marked" in grid[x][y]) {
            if (grid[x][y].marked) {
                return baseClass + " rm-solved";
            }
            return baseClass + " rm-pass-cell";
        }

        return baseClass + " rm-solved";
    }

    // If cell is selected by the user
    if (isSelected) {
        if (isWrongValue) {
            return baseClass + " rm-wrong-sec";
        }
        if (isMarked) {
            return baseClass + " rm-solved-sec";
        }
        if (isCorrectValue) {
            return baseClass + " rm-solved-sec";
        }
        return baseClass + " rm-selected";
    } else {
        if (isWrongValue) {
            return baseClass + " rm-wrong";
        }
        if (isMarked) {
            return baseClass + " rm-solved";
        }
        if (isCorrectValue) {
            return baseClass + " rm-solved";
        }
    }

    if (isMarked) {
        return baseClass + " rm-solved";
    }

    return baseClass;
}

export function getHintClass(hint) {
    if (hint.t === "e") {
        return "rm-locked rm-hints-empty";
      }
      if (hint.t === "n") {
        return "rm-hints-number";
      } else if (hint.t === "h") {
        return "rm-hints-value";
      }
      return "";
}

export function getInitialGridCellConfig(game) {
    let initialObj = {
        type: null,
        value: null,
        html: null,
        original_value: null,
        solution: null,
        // Locked cells are not meant to be changed,
        // so they are excluded when checking for solutions
        locked: false,
        selected: false,
        correct_value: false,
        wrong_value: false,
    };

    if (game === config.games.props.wordSearch.id) {
        initialObj.marked = false;
    }

    return initialObj;
}

export function resetGridData(state) {
    for (let i = 0; i < state.grid.length; i++) {
        for (let j = 0; j < state.grid[i].length; j++) {
            if (state.grid[i][j].locked) continue;

            state.grid[i][j].selected = false;
            state.grid[i][j].correct_value = false;
            state.grid[i][j].wrong_value = false;

            state.grid[i][j].value = state.grid[i][j].original_value;

            Vue.set(state.grid, i, state.grid[i]);
        }
    }
}
