import {config} from "@/config/config";
import {getInitialGridCellConfig} from "@/config/stores/common";

export function setEmptyGrids(numRows, numCols) {
    let gridString = [];
    let grid = [];

    let initialCellConfig = getInitialGridCellConfig(
        config.games.props.sudoku.id
    );
    // Manually setting it to true so user in initial state cannot click on anything
    initialCellConfig.locked = true;

    for (let i = 0; i < numRows; i++) {
        grid[i] = [];
        for (let j = 0; j < numCols; j++) {
            gridString = gridString.concat("0");
            grid[i][j] = initialCellConfig;
        }
    }

    return {
        completed: false,
        gridId: null,
        partialGridString: gridString,
        partialGrid: grid,
        solvedGridString: gridString,
        solvedGrid: grid,
    };
}

export function setGrids(data) {
    const num_rows = data.num_rows;
    const num_cols = data.num_cols;

    const initialGridString = data.initial_grid;
    const partialGridString = data.partial_grid;
    const solvedGridString = data.solved_grid;

    let partialGrid = [];
    let solvedGrid = [];

    for (let i = 0; i < num_rows; i++) {
        partialGrid[i] = [];
        solvedGrid[i] = [];
        for (let j = 0; j < num_cols; j++) {
            let index = num_rows * i + j;
            let initialLetter = initialGridString.substring(index, index + 1);
            let partialLetter = partialGridString.substring(index, index + 1);
            let solvedLetter = solvedGridString.substring(index, index + 1);

            initialLetter = initialLetter === "0" ? "" : initialLetter;
            partialLetter = partialLetter === "0" ? "" : partialLetter;

            partialGrid[i][j] = {
                value: partialLetter,
                original_value: initialLetter,
                locked: initialLetter !== "",
                solution: solvedLetter,
                selected: false,
                wrong_value: false,
                correct_value: false,
            };
        }
    }

    return {
        completed: data.completed,
        gridId: data.gridId,
        partialGridString: partialGridString,
        partialGrid: partialGrid,
        solvedGridString: solvedGridString,
        solvedGrid: solvedGrid,
    };
}

export function setState(state, grids) {
    state.completed = grids.completed;
    state.gridId = grids.gridId;
    state.origString = grids.partialGridString;
    state.grid = grids.partialGrid;
    state.solution = grids.solvedGridString;
    state.solutionGrid = grids.solvedGrid;
}
