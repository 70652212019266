<script>
import {
  setEmptyGrids,
  setGrids,
  setState,
} from "@/_helpers/games/memory-helper";
import { gamesService } from "@/_services";
import { config } from "@/config/config";
import { handleError } from "@/_helpers/api";
import router from "@/config/PageRoutes";
import { isEqual } from "lodash";
import Vue from "vue";
import {commonStoreState, setInitialState, updateGameData} from "@/config/stores/common";

const memoryStore = {
  namespaced: true,
  state: {
    ...commonStoreState,
    // game specific vars
    gridSymbols: null,
    words: null,
    foundWords: [],
    wordSize: 0,
    step: null,
    selected: null,
    selectedWord: null,
    selectedCoords: [],
    maxSelectedCoords: 0,
    wordFound: false,
  },
  mutations: {
    async initGrid(state, gridData) {
      const period = gridData.period;
      const gridId = gridData.gridId;

      setInitialState(state);
      state.gridId = gridId;
      state.period = period;

      let emptyGrids = setEmptyGrids(8, 4, 4);
      state.grid = emptyGrids.grid;
      state.gridSymbols = emptyGrids.gridSymbols;
      state.words = null;
      state.foundWords = [];
      state.selectedCoords = [];
      state.maxSelectedCoords = 0;

      if (period !== "") {
        gamesService
          .get_periodic(config.games.props.memory.id, period)
          .then((data) => {
            let grids = setGrids(8, data);
            state.completed = data.completed;
            setState(state, grids);

            processResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      } else if (gridId !== "") {
        gamesService
          .get(config.games.props.memory.id, gridId)
          .then((data) => {
            let grids = setGrids(8, data);
            state.completed = data.completed;
            setState(state, grids);

            processResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      }
    },
    // eslint-disable-next-line no-unused-vars
    resetGrid(state, value) {
      for (let i = 0; i < state.grid.length; i++) {
        for (let j = 0; j < state.grid[i].length; j++) {
          state.grid[i][j].selected = false;
          state.grid[i][j].correct_value = false;
          state.grid[i][j].wrong_value = false;

          state.grid[i][j].value = "";

          Vue.set(state.grid, i, state.grid[i]);
        }
      }

      state.selectedCoords = [];
      state.foundWords = [];

      processMemoryResult(state, true);
    },
    // Function selects or deselects certain field
    setSelected(state, pos) {
      if (state.solutionAchieved) {
        return;
      }

      const x = parseInt(pos.x);
      const y = parseInt(pos.y);

      if (state.grid[x][y].selected) {
        return;
      }

      if (state.selectedCoords.length >= state.maxSelectedCoords) {
        return;
      }

      selectField(state, x, y);

      if (state.selectedCoords.length < state.maxSelectedCoords) {
        return;
      }

      state.selectedCoords.sort();

      let isMatch = false;
      let matchKey = "";

      const keys = Object.keys(state.words);
      for (let i = 0; i < keys.length; i++) {
        let keyArr = state.words[keys[i]];
        keyArr.sort();

        if (isEqual(state.selectedCoords, keyArr)) {
          isMatch = true;
          matchKey = keys[i];
          break;
        }
      }

      if (isMatch) {
        markCorrectFields(state);
        clearSelectedCoords(state);

        state.foundWords.push(matchKey);
        processMemoryResult(state, false);

        return;
      }

      markWrongFields(state);

      setTimeout(function () {
        clearSelectedFields(state);
        clearSelectedCoords(state);
      }, 1000);
    },
  },
};

function selectField(state, x, y) {
  state.grid[x][y].selected = true;
  state.selectedCoords.push([x, y]);
  state.grid[x][y].value = state.grid[x][y].solution;
  Vue.set(state.grid, x, state.grid[x]);
}

function markWrongFields(state) {
  for (let i = 0; i < state.selectedCoords.length; i++) {
    const el = state.selectedCoords[i];
    state.grid[el[0]][el[1]].wrong_value = true;
    state.grid[el[0]][el[1]].selected = false;
    Vue.set(state.grid, el[0], state.grid[el[0]]);
  }
}

function markCorrectFields(state) {
  for (let i = 0; i < state.selectedCoords.length; i++) {
    const el = state.selectedCoords[i];
    state.grid[el[0]][el[1]].selected = false;
    Vue.set(state.grid, el[0], state.grid[el[0]]);
  }
}

function clearSelectedFields(state) {
  for (let i = 0; i < state.selectedCoords.length; i++) {
    const el = state.selectedCoords[i];
    state.grid[el[0]][el[1]].selected = false;
    state.grid[el[0]][el[1]].wrong_value = false;
    state.grid[el[0]][el[1]].value = "";
    Vue.set(state.grid, el[0], state.grid[el[0]]);
  }
}

function clearSelectedCoords(state) {
  state.selectedCoords = [];
}

function processResult(state) {
  state.allFilledUp = areAllValuesFound(state);
  state.solutionAchieved = state.allFilledUp;
}

function areAllValuesFound(state) {
  for (let i = 0; i < state.grid.length; i++) {
    for (let x = 0; x < state.grid[i].length; x++) {
      if (state.grid[i][x].value !== state.grid[i][x].original_value)
        return false;
    }
  }
  return true;
}

function processMemoryResult(state, initialState = false) {
  // Do not invoke APIs if resetting already reset state
  if (initialState && state.initialState) {
    return;
  }

  state.initialState = initialState;

  processResult(state);

  if (!state.completed) {
    updateUserMemoryInfo(state);
    if (state.solutionAchieved) {
      state.completed = true;
    }
  }
}

function updateUserMemoryInfo(state) {
  let data = {
    gridId: state.gridId,
    foundWords: state.foundWords,
    completed: state.solutionAchieved,
  };

  updateGameData(config.games.props.memory.id, data, state.period)
}

export default memoryStore;
</script>
