export function getDomain() {

    let domain = window.location.hostname;

    if(domain.toUpperCase().startsWith("WWW.")){
        domain = domain.substring(4)
    }

    return domain;
}

export function getCapitalizedDomainNameAndExtension(){
    let domain = getCapitalizedDomain()
    if(!domain.includes(".")){
        return {
            name: domain,
            extension: ""
        }
    }

    let spl = domain.split(".")

    return {
        name: spl[0],
        extension: spl[1]
    }
}

export function getCapitalizedDomain() {
    let domain = getDomain();
    let firstLetter = domain.charAt(0)
    firstLetter = firstLetter.toUpperCase();
    let remainingLetters = domain.slice(1)

    return firstLetter + remainingLetters
}

export function getLocalStorageUserItemName() {
    return getDomain() + "-user"
}

export function getLocalStorageUserLocaleName() {
    return getDomain() + "-locale"
}
