<script>
import {config} from "@/config/config";
import {gamesService} from "@/_services";
import router from "../../config/PageRoutes";
import {setEmptyGrid, setGrid,} from "@/_helpers/games/minesweeper-helper";
import {handleError} from "@/_helpers/api";
import {commonStoreState, setInitialState, updateGameData} from "@/config/stores/common";

const minesweeperStore = {
  namespaced: true,
  state: {
    ...commonStoreState,
    // game specific vars
    numMines: null,
    killed: false,
    selectedCell: null,
  },
  mutations: {
    async initGrid(state, gridData) {
      const period = gridData.period;
      const gridId = gridData.gridId;

      state.grid = setEmptyGrid(10, 10);

      setInitialState(state);
      state.period = period;
      state.gridId = gridId;
      state.numMines = null;
      state.killed = false;
      state.selectedCell = null;

      if (period !== "") {
        gamesService
          .get_periodic(config.games.props.minesweeper.id, period)
          .then((data) => {
            state.completed = data.completed;
            state.gridId = data.gridId;
            state.grid = setGrid(JSON.parse(data.grid));
            state.numMines = data.numMines;

            processMinesweeperResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      } else if (gridId !== "") {
        gamesService
          .get(config.games.props.minesweeper.id, gridId)
          .then((data) => {
            state.completed = data.completed;
            state.gridId = data.gridId;
            state.grid = setGrid(JSON.parse(data.grid));
            state.numMines = data.numMines;

            processMinesweeperResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      }
    },
    setSelected(state, pos) {
      if (
        "locked" in state.grid[pos.x][pos.y] &&
        state.grid[pos.x][pos.y].locked
      )
        return;

      if (state.killed || state.solutionAchieved) return;

      // Using left click on already marked mine
      if (state.grid[pos.x][pos.y].u === "M") return;

      // Using left click on already revealed cell
      if (state.grid[pos.x][pos.y].u === "R") return;

      setSelectedPosition(state, pos);
      revealNeighbors(state, pos);

      processMinesweeperResult(state);
    },
    markMine(state, pos) {
      if (
        "locked" in state.grid[pos.x][pos.y] &&
        state.grid[pos.x][pos.y].locked
      )
        return;

      if (state.killed || state.solutionAchieved) return;

      // Do not mark already revealed cell
      if (state.grid[pos.x][pos.y].u === "R") return;

      // If mine is not revealed yet
      if (state.grid[pos.x][pos.y].u === "I") {
        state.grid[pos.x][pos.y].u = "M";
      }
      // If mine is marked
      else if (state.grid[pos.x][pos.y].u === "M") {
        state.grid[pos.x][pos.y].u = "I";
      }

      processMinesweeperResult(state);
    },
    resetGrid(state) {
      for (let i = 0; i < state.grid.length; i++) {
        for (let x = 0; x < state.grid[i].length; x++) {
          state.grid[i][x].u = "I";
        }
      }

      state.killed = false;
      state.selectedCell = null;
      state.solutionAchieved = false;

      processMinesweeperResult(state, true);
    },
  },
  actions: {},
};

function processMinesweeperResult(state, initialState = false) {
  // Do not invoke APIs if resetting already reset state
  if (state.initialState && initialState) {
    return;
  }
  state.initialState = initialState;

  state.solutionAchieved = areAllFieldsSolved(state);

  if (!state.completed && !state.killed) {
    updateUserMinesweeperInfo(state);
    if (state.solutionAchieved) {
      state.completed = true;
    }
  }
}

function areAllFieldsSolved(state) {
  if (state.killed) {
    return false;
  }

  // Mines does not have to be marked for the game to be solved!
  for (let i = 0; i < state.grid.length; i++) {
    for (let j = 0; j < state.grid[i].length; j++) {
      if ("locked" in state.grid[i][j] && state.grid[i][j].locked) return false;

      if (state.grid[i][j].t === "E") {
        // Empty field must be revealed
        if (state.grid[i][j].u !== "R") {
          return false;
        }
      }
    }
  }

  return true;
}

function setSelectedPosition(state, pos) {
  if (state.killed || state.solutionAchieved) {
    return;
  }

  if (state.grid[pos.x][pos.y].u === "R") {
    return;
  }

  state.selectedCell = { x: pos.x, y: pos.y };

  const t = state.grid[pos.x][pos.y].t;

  if (t === "M") {
    state.killed = true;
  } else if (t === "E") {
    state.grid[pos.x][pos.y].u = "R";
  }
}

function revealNeighbors(state, pos) {
  // Do nothing if game is finished
  if (state.killed || state.solutionAchieved) {
    return;
  }

  let x = pos.x;
  let y = pos.y;

  // Reveal neighbors recursively only if we clicked on empty field which has value zero (0)
  if (state.grid[x][y].t === "E" && state.grid[x][y].n < 1) {
    revealNeighborsRec(state, pos);
  }
}

function revealNeighborsRec(state, pos) {
  if (state.killed || state.solutionAchieved) {
    return;
  }

  let x = pos.x;
  let y = pos.y;

  if (state.grid[x][y].t === "E" && state.grid[x][y].n < 1) {
    state.grid[x][y].u = "R";
  }

  // Up
  const upX = x - 1;
  if (x > 0 && state.grid[upX][y].t === "E" && state.grid[upX][y].u === "I") {
    state.grid[upX][y].u = "R";
    if (state.grid[upX][y].n < 1) {
      revealNeighborsRec(state, { x: upX, y: y });
    }
  }

  // Down
  const downX = x + 1;
  if (
    x < state.grid.length - 1 &&
    state.grid[downX][y].t === "E" &&
    state.grid[downX][y].u === "I"
  ) {
    state.grid[downX][y].u = "R";
    if (state.grid[downX][y].n < 1) {
      revealNeighborsRec(state, { x: downX, y: y });
    }
  }

  // Left
  const leftY = y - 1;
  if (
    y > 0 &&
    state.grid[x][leftY].t === "E" &&
    state.grid[x][leftY].u === "I"
  ) {
    state.grid[x][leftY].u = "R";
    if (state.grid[x][leftY].n < 1) {
      revealNeighborsRec(state, { x: x, y: leftY });
    }
  }

  // Right
  const rightY = y + 1;
  if (
    y < state.grid[x].length - 1 &&
    state.grid[x][rightY].t === "E" &&
    state.grid[x][rightY].u === "I"
  ) {
    state.grid[x][rightY].u = "R";
    if (state.grid[x][rightY].n < 1) {
      revealNeighborsRec(state, { x: x, y: rightY });
    }
  }
}

function updateUserMinesweeperInfo(state) {
  let data = {
    gridId: state.gridId,
    grid: state.grid,
    completed: state.solutionAchieved,
  };

  updateGameData(config.games.props.minesweeper.id, data, state.period)
}

export default minesweeperStore;
</script>
