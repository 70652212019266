<script>
import Vue from "vue";
import Vuex from "vuex";

import caesarCypherStore from "@/config/stores/CaesarCypherStore";
import crosswordStore from "./CrosswordStore.vue";
import sudokuStore from "./SudokuStore";
import kakuroStore from "./KakuroStore";
import minesweeperStore from "@/config/stores/MinesweeperStore";
import wordSearchStore from "@/config/stores/WordSearchStore";
import simpleMazeStore from "@/config/stores/SimpleMazeStore";
import mathCrosswordStore from "@/config/stores/MathCrosswordStore";
import memoryStore from "@/config/stores/MemoryStore";
import simpleMathStore from "@/config/stores/SimpleMathStore";
import memeStore from "@/config/stores/MemeStore";
import mathStore from "@/config/stores/MathStore.vue";
import {alert} from "./AlertStore";
import {account} from "./AccountStore";
import {users} from "./UserStore";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    alert,
    account,
    users,
    caesarCypherStore,
    crosswordStore,
    sudokuStore,
    kakuroStore,
    minesweeperStore,
    wordSearchStore,
    simpleMazeStore,
    mathCrosswordStore,
    memoryStore,
    simpleMathStore,
    memeStore,
    mathStore,
  },
});

export default store;
</script>
