import {getDomain, getLocalStorageUserLocaleName} from "@/_helpers/domain_helper";

export function getValidLocaleList() {
    return ['si', 'us']
}

function getDefaultLocale() {
    const domain = getDomain();
    switch (domain) {
        case "localhost": return "si";
        case "resi-me.si": return "si";
        case "cyphio.com": return "us";
        default: return "si";
    }
}

export function getLocale() {
    let locale = window.localStorage.getItem(getLocalStorageUserLocaleName());

    const validLocales = getValidLocaleList();
    if (locale !== null && locale && validLocales.includes(locale)) {
        return locale;
    }

    return getDefaultLocale();
}

export function getLoginButtonLocale() {
    const locale =getLocale();
    switch (locale) {
        case "si": return "sl_SI";
        case "us": return "en_GB";
        case "de": return "de_DE";
        default: return "sl_SI";
    }
}

export function getDateTimeLocale() {
    const locale =getLocale();
    switch (locale) {
        case "si": return "sl-SI";
        case "us": return "en-GB";
        case "de": return "de-DE";
        default: return "sl-SI";
    }
}


export function getFallbackLocale() {
    return 'si';
}

export function setLocale(loc, vue_instance) {

    const validLocales = getValidLocaleList();
    if (loc === null || !validLocales.includes(loc)) {
        loc = getLocale();
    }

    vue_instance.$i18n.locale = loc;
    vue_instance.$root.locale = loc;

    window.localStorage.setItem(getLocalStorageUserLocaleName(), loc);
}
