<script>
import Vue from "vue";
import router from "../../config/PageRoutes";
import { config } from "@/config/config";
import { gamesService } from "@/_services";
import { handleError } from "@/_helpers/api";
import {commonStoreState, setInitialState, updateGameData} from "@/config/stores/common";

const simpleMazeStore = {
  namespaced: true,
  state: {
    ...commonStoreState,
    // game specific vars
    words: null,
    wordSize: 0,
    step: null,
    selected: null,
    startCell: [],
    endCell: [],
    selectedCell: [],
  },
  mutations: {
    async initGrid(state, gridData) {
      const period = gridData.period;
      const gridId = gridData.gridId;

      setInitialState(state);
      state.period = period;
      state.gridId = gridId;
      state.initialState = true;

      if (period !== "") {
        gamesService
          .get_periodic(config.games.props.simpleMaze.id, period)
          .then((data) => {
            state.gridId = data.gridId;
            state.grid = data.grid;
            state.completed = data.completed;
            state.startCell = data.positions.s;
            state.endCell = data.positions.e;
            state.selectedCell = [...data.positions.s];

            if (state.completed) {
              state.initialState = false;
              state.selectedCell = [...data.positions.e];
            }

            processResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      } else if (gridId !== "") {
        gamesService
          .get(config.games.props.simpleMaze.id, gridId)
          .then((data) => {
            state.gridId = data.gridId;
            state.grid = data.grid;
            state.completed = data.completed;
            state.startCell = data.positions.s;
            state.endCell = data.positions.e;
            state.selectedCell = [...data.positions.s];

            if (state.completed) {
              state.initialState = false;
              state.selectedCell = [...data.positions.e];
            }

            processResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      }
    },
    resetGrid(state) {
      state.selectedCell = [...state.startCell];
      processSimpleMazeResult(state, true);
    },
    moveInDirection(state, direction) {
      if (state.solutionAchieved) return;

      if (canMoveInDirection(state, state.selectedCell, direction)) {
        // Go up
        if (direction === 0) {
          Vue.set(state.selectedCell, 0, state.selectedCell[0] - 1);
        }
        // Go right
        if (direction === 3) {
          Vue.set(state.selectedCell, 1, state.selectedCell[1] + 1);
        }
        // Go down
        if (direction === 1) {
          Vue.set(state.selectedCell, 0, state.selectedCell[0] + 1);
        }
        // Go left
        if (direction === 2) {
          Vue.set(state.selectedCell, 1, state.selectedCell[1] - 1);
        }

        processSimpleMazeResult(state);
      }
    },
    moveToPosition(state, pos) {
      // Do nothing if user clicked on already selected cell
      if (state.selectedCell[0] === pos.x && state.selectedCell[1] === pos.y) {
        return;
      }

      // Do nothing if user clicked on other row and other col
      if (state.selectedCell[0] !== pos.x && state.selectedCell[1] !== pos.y) {
        return;
      }

      // Only movements in the same row or col are allowed - no diagonal moves!
      let canMove = true;

      let currX = state.selectedCell[0];
      let currY = state.selectedCell[1];

      // User wants to go up
      if (pos.x < state.selectedCell[0]) {
        while (currX > pos.x) {
          if (!canMoveInDirection(state, [currX, state.selectedCell[1]], 0)) {
            canMove = false;
            break;
          }
          currX = currX - 1;
        }
      }
      // User wants to go down
      else if (pos.x > state.selectedCell[0]) {
        while (currX < pos.x) {
          if (!canMoveInDirection(state, [currX, state.selectedCell[1]], 1)) {
            canMove = false;
            break;
          }
          currX = currX + 1;
        }
      }
      // User wants to go left
      else if (pos.y < state.selectedCell[1]) {
        while (currY > pos.y) {
          if (!canMoveInDirection(state, [state.selectedCell[0], currY], 2)) {
            canMove = false;
            break;
          }
          currY = currY - 1;
        }
      }
      // User wants to go right
      else if (pos.y > state.selectedCell[1]) {
        while (currY < pos.y) {
          if (!canMoveInDirection(state, [state.selectedCell[0], currY], 3)) {
            canMove = false;
            break;
          }
          currY = currY + 1;
        }
      }

      if (canMove) {
        Vue.set(state.selectedCell, 0, pos.x);
        Vue.set(state.selectedCell, 1, pos.y);
      }
    },
  },
};

function canMoveInDirection(state, selectedCell, direction) {
  const cellWalls = state.grid[selectedCell[0]][selectedCell[1]];

  // First cell
  if (
    selectedCell[0] === state.startCell[0] &&
    selectedCell[1] === state.startCell[1]
  ) {
    // Do we want to go up or left
    if (direction === 0 || direction === 2) {
      return false;
    }

    // Trying to go right but there is a wall
    if (cellWalls.substr(1, 1) === "1" && direction === 3) {
      return false;
    }

    // Trying to go down but there is a wall
    if (cellWalls.substr(2, 1) === "1" && direction === 1) {
      return false;
    }

    return true;
  }

  if (
    selectedCell[0] === state.endCell[0] &&
    selectedCell[1] === state.endCell[1]
  ) {
    // Do we want to go down or right
    if (direction === 1 || direction === 3) {
      return false;
    }

    // Trying to go up but there is a wall
    if (cellWalls.substr(0, 1) === "1" && direction === 0) {
      return false;
    }

    // Trying to go left but there is a wall
    if (cellWalls.substr(3, 1) === "1" && direction === 2) {
      return false;
    }
  }

  // Going up but cant
  if (direction === 0 && cellWalls.substr(0, 1) === "1") {
    return false;
  }
  // Going right but cant
  else if (direction === 3 && cellWalls.substr(1, 1) === "1") {
    return false;
  }
  // Going down but cant
  else if (direction === 1 && cellWalls.substr(2, 1) === "1") {
    return false;
  }
  // Going down but cant
  else if (direction === 2 && cellWalls.substr(3, 1) === "1") {
    return false;
  }

  return true;
}

function processSimpleMazeResult(state, initialState = false) {
  // Do not invoke APIs if resetting already reset state
  if (initialState && state.initialState) {
    return;
  }

  state.initialState = initialState;

  processResult(state);

  if (!state.completed) {
    if (state.solutionAchieved) {
      updateSimpleMazeInfo(state);
      state.completed = true;
    }
  }
}

function updateSimpleMazeInfo(state) {
  let data = {
    gridId: state.gridId,
    completed: state.solutionAchieved,
  };

  updateGameData(config.games.props.simpleMaze.id, data, state.period)
}

function processResult(state) {
  state.allFilledUp =
    state.selectedCell[0] === state.endCell[0] &&
    state.selectedCell[1] === state.endCell[1];
  state.solutionAchieved = state.allFilledUp;
}

export default simpleMazeStore;
</script>
