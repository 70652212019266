<script>
import {setEmptyGrids} from "@/_helpers/games/meme-helper";
import {commonStoreState, setInitialState, setSelectedPosition, updateGameData} from "@/config/stores/common";
import Vue from "vue";
import {gamesService} from "@/_services";
import {config} from "@/config/config";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import {
  calculateRowResult,
  findNextEmptyCellInRow,
  setGrids,
  setCellHtml,
  isRowCorrect
} from "@/_helpers/games/meme-helper";

const memeStore = {
  namespaced: true,
  state: {
    ...commonStoreState,
    // game specific vars
    gridSymbols: null,
    words: null,
    foundWords: [],
    wordSize: 0,
    solution: null,
    step: null,
    selected: null,
    selectedWord: null,
    selectedCoords: [],
    maxSelectedCoords: 0,
    wordFound: false,
  },
  mutations: {
    async initGrid(state, gridData) {
      const period = gridData.period;
      const gridId = gridData.gridId;

      setInitialState(state);
      state.gridId = gridId;
      state.period = period;

      let emptyGrids = setEmptyGrids(6, 4);

      state.grid = emptyGrids.grid;
      state.wordSize = emptyGrids.wordSize;
      state.gridSymbols = emptyGrids.gridSymbols;

      if (period !== "") {
        gamesService
            .get_periodic(config.games.props.meme.id, period)
            .then((data) => {
              let grids = setGrids(data);

              state.gridId = grids.gridId;
              state.grid = grids.grid;
              state.gridSymbols = grids.gridSymbols;
              state.wordSize = grids.wordSize;
              state.solution = grids.solution;

              processMemeGrid(state);
              if (state.allFilledUp && !state.solutionAchieved) {
                // Unlock last row so user can still play
                unlockLastRow(state);
              }
              setSelectedPosition(state, {x: grids.activeX, y: grids.activeY})
            })
            .catch((error) => handleError(error, router, this._vm));
      } else if (gridId !== "") {
        gamesService
            .get(config.games.props.meme.id, gridId)
            .then((data) => {
              let grids = setGrids(data);

              state.gridId = grids.gridId;
              state.grid = grids.grid;
              state.gridSymbols = grids.gridSymbols;
              state.wordSize = grids.wordSize;
              state.solution = grids.solution;

              processMemeGrid(state);

              if (state.allFilledUp && !state.solutionAchieved) {
                // Unlock last row so user can still play
                unlockLastRow(state);
              }

              setSelectedPosition(state, {x: grids.activeX, y: grids.activeY})
            })
            .catch((error) => handleError(error, router, this._vm));
      }
    },
    resetGrid(state) {

      for (let i = 0; i < state.grid.length; i++) {
        for (let j = 1; j < state.grid[i].length - 1; j++) {
          state.grid[i][j].locked = i !== 0;

          state.grid[i][j].selected = i === 0 && j === 1;
          state.grid[i][j].correct_value = false;
          state.grid[i][j].wrong_value = false;
          state.grid[i][j].value = "";
          state.grid[i][j].html = "";
        }

        state.grid[i][state.grid[i].length - 1].html = "";

        Vue.set(state.grid, i, state.grid[i]);
      }
      state.selected = {x: 0, y: 1};

      state.allFilledUp = false;
      state.hasWrongValues = false;
      state.solutionAchieved = false;

      if (state.completed) return;

      updateUserMemeInfo(state, 0, 0, 0, true);
    },
    setSelected(state, pos) {
      if (state.solutionAchieved) return;
      setSelectedPosition(state, pos);
    },
    setNumber(state, num) {
      if (state.solutionAchieved) return;

      // Do not set value is nothing is selected
      if (!state.selected) return;

      let selectedRow = state.selected.x;
      let selectedCol = state.selected.y;

      // Make sure that user entered integer
      let parsedValue = parseInt(num);
      if (isNaN(parsedValue) || !Number.isInteger(parsedValue)) {
        num = "";
      }

      // Do not change value if value is the same
      if (state.grid[selectedRow][selectedCol].value === num) return;

      let cellHtml = '';
      if (num !== "") {
        cellHtml = setCellHtml(num);
      }

      state.grid[selectedRow][selectedCol].value = num;
      state.grid[selectedRow][selectedCol].html = cellHtml;
      Vue.set(state.grid, selectedRow, state.grid[selectedRow]);

      if (num === 0) {
        updateUserMemeInfo(state, selectedRow, selectedCol - 1, num);
        return;
      }

      let rowResult = checkFilledRow(state);
      if (rowResult === null) {
        // Row is not filled yet, select next cell
        let nextEmptyCell = findNextEmptyCellInRow(state.grid, selectedRow, selectedCol);
        if (nextEmptyCell !== -1) {
          setSelectedPosition(state, {x: selectedRow, y: selectedCol});
          setSelectedPosition(state, {x: selectedRow, y: nextEmptyCell});
        }
      } else {
        let shouldMoveToNextRow = processMemeGrid(state)
        if (shouldMoveToNextRow) {
          moveToNextRow(state, selectedRow, selectedCol);
        }
      }

      updateUserMemeInfo(state, selectedRow, selectedCol - 1, num);
    },
  }
}

function unlockLastRow(state) {
  let numRows = state.grid.length;
  for (let j = 1; j < state.grid[numRows - 1].length - 1; j++) {
    state.grid[numRows - 1][j].locked = false;
  }
}

function processMemeGrid(state) {
  // Row is filled at this time, maybe solution is achieved
  let gridInfo = processGrid(state.grid);

  state.solutionAchieved = gridInfo.solutionFound;

  if (gridInfo.solutionFound) {
    state.hasWrongValues = false;
    state.allFilledUp = true;
  } else {
    if (gridInfo.allFilledUp) {
      // Entire grid is filled
      state.hasWrongValues = true;
      state.allFilledUp = true;
      return true;
    } else {
      // Grid is not filled yet, just move to next row
      return true;
    }
  }

}

function processGrid(grid) {
  // Check if we all values are filled and/or we have a correct solution

  let allFilled = true;
  let solutionFound = false;
  let solutionRow = -1;

  for (let i = 0; i < grid.length; i++) {
    for (let j = 1; j < grid[i].length - 1; j++) {
      if (grid[i][j].value === "") {
        allFilled = false;
      }
    }

    if (isRowCorrect(grid[i])) {
      solutionFound = true;
      solutionRow = i;
    }
  }

  return {
    allFilledUp: allFilled,
    solutionFound: solutionFound,
    solutionRow: solutionRow
  }
}

function moveToNextRow(state, selectedRow, selectedCol) {
  if (selectedRow < state.grid.length - 1) {
    for (let j = 1; j < state.grid[selectedRow].length - 1; j++) {
      state.grid[selectedRow][j].locked = true;
    }
    setSelectedPosition(state, {x: selectedRow, y: selectedCol});

    for (let j = 1; j < state.grid[selectedRow + 1].length - 1; j++) {
      state.grid[selectedRow + 1][j].locked = false;
    }
    setSelectedPosition(state, {x: selectedRow + 1, y: 1});
  }
}

function checkFilledRow(state) {
  // Find selected cell
  let selectedRow = state.selected.x;

  let rowResult = calculateRowResult(state.grid, selectedRow, state.solution);

  if (rowResult !== null) {
    state.grid[selectedRow][state.wordSize + 1].html = rowResult.cHtml;
    Vue.set(state.grid, selectedRow, state.grid[selectedRow]);
  }

  return rowResult;

}

function updateUserMemeInfo(state, x, y, num, reset = false) {
  let data = {
    gridId: state.gridId,
    row: x,
    column: y,
    value: num,
    completed: state.solutionAchieved,
    reset: reset
  };

  updateGameData(config.games.props.meme.id, data, state.period)
}

export default memeStore;
</script>