import {getInitialGridCellConfig} from "@/config/stores/common";
import {config} from "@/config/config";

export function setSampleCrosswordGrids(numRows, numCols, hintRowLength) {
    const grid = [];

    let hintsS = [[]];
    let hintsE = [[]];

    let initialGridCell = getInitialGridCellConfig(
        config.games.props.mathCrossword.id
    );
    // Manually setting it to true so user in initial state cannot click on anything
    initialGridCell.locked = true;
    initialGridCell.type = "E";

    for (let i = 0; i < hintRowLength; i++) {
        hintsE[0].push({t: "e"});
        hintsE[0].push({t: "e"});
        hintsS[0].push({t: "e"});
        hintsS[0].push({t: "e"});
    }

    for (let i = 0; i < numRows; i++) {
        grid.push([]);
        for (let j = 0; j < numCols; j++) {
            grid[i].push({...initialGridCell});
        }
    }

    const h1 = "Avtor spletne strani www.resi-me.si"
    const h2 = "Priroda"
    const h3 = "Kozic"

    // region Word1
    grid[1][0].type = "H"
    grid[1][0].locked = false;
    grid[1][0].value = 1;
    grid[1][0].he = h1;

    grid[1][1].type = "L"
    grid[1][1].locked = false;
    grid[1][1].value = "N";

    grid[1][2].type = "L";
    grid[1][2].locked = false;
    grid[1][2].value = "E";

    grid[1][3].type = "L";
    grid[1][3].locked = false;
    grid[1][3].value = "J";

    grid[1][4].type = "L";
    grid[1][4].locked = false;
    grid[1][4].value = "C";

    hintsE[0][0] = { t: 'n', v: 1}
    hintsE[0][1] = { t: 'h', v: h1}
    // endregion

    // region Word 2
    grid[0][1].type = "H"
    grid[0][1].locked = false;
    grid[0][1].value = 2;
    grid[0][1].hs = h2;

    grid[1][1].type = "L"
    grid[1][1].locked = false;
    grid[1][1].value = "N";

    grid[2][1].type = "L"
    grid[2][1].locked = false;
    grid[2][1].value = "A";

    grid[3][1].type = "L"
    grid[3][1].locked = false;
    grid[3][1].value = "R";

    grid[4][1].type = "L"
    grid[4][1].locked = false;
    grid[4][1].value = "A";

    grid[5][1].type = "L"
    grid[5][1].locked = false;
    grid[5][1].value = "V";

    grid[6][1].type = "L"
    grid[6][1].locked = false;
    grid[6][1].value = "A";

    hintsS[0][0] = { t: 'n', v: 1}
    hintsS[0][1] = { t: 'h', v: h2}
    // endregion

    // region Word3
    grid[0][4].type = "H"
    grid[0][4].locked = false;
    grid[0][4].value = 3;
    grid[0][4].hs = h3;

    grid[2][4].type = "L"
    grid[2][4].locked = false;
    grid[2][4].value = "K";

    grid[3][4].type = "L"
    grid[3][4].locked = false;
    grid[3][4].value = "O";

    grid[4][4].type = "L"
    grid[4][4].locked = false;
    grid[4][4].value = "T";

    hintsS[0][2] = { t: 'n', v: 3}
    hintsS[0][3] = { t: 'h', v: h3}
    // endregion

    return {
        numRows: numRows,
        numCols: numCols,
        gridId: null,
        grid: grid,
        hintsE: hintsE,
        hintsS: hintsS,
    };
}