import {getInitialGridCellConfig} from "@/config/stores/common";
import {config} from "@/config/config";


export function setGrids(data) {

    let gridSymbols = Array.from({length: data.numValues}, (_, i) => i + 1)

    let grid = [];

    let rowWithSolution = -1;
    let rowWithEmptyField = -1;
    let firstEmptyCell = -1;

    // First cell is a number
    // X cells are actual fields
    // Last cell is a result cell

    for (let i = 0; i < data.numGuesses; i++) {
        grid[i] = [];

        // Push first cell which is a simple number
        let firstCellConfig = getInitialGridCellConfig(
            config.games.props.meme.id
        );
        firstCellConfig.locked = true;
        firstCellConfig.value = (i + 1).toString();
        grid[i].push(firstCellConfig);

        for (let j = 1; j <= data.numFields; j++) {
            let initialCellConfig = getInitialGridCellConfig(
                config.games.props.meme.id
            );

            let value = "";
            let cellHtml = "";
            if (data.grid[i][j - 1] > 0) {
                value = data.grid[i][j - 1];
                cellHtml = setCellHtml(value);
            } else if (rowWithEmptyField === -1) {
                rowWithEmptyField = i;
                firstEmptyCell = j;
            }

            initialCellConfig.locked = true;
            initialCellConfig.value = value;
            initialCellConfig.html = cellHtml;
            initialCellConfig.solution = data.solution[j - 1];
            grid[i].push(initialCellConfig);
        }

        if (isRowCorrect(grid[i])) {
            rowWithSolution = i;
        }

        // Push last cell which is a result of guesses
        let initialCellConfig = getInitialGridCellConfig(
            config.games.props.memory.id
        );
        initialCellConfig.locked = true;
        grid[i].push(initialCellConfig);

        grid = processRow(grid, i, data.solution);
    }

    // Figure out which row to lock
    // - if game is solved, unlock row with solution so it can be "green"
    // - if game is not solved, unlock first row with empty cell
    let rowToUnlock = data.completed ? rowWithSolution : rowWithEmptyField;
    if (rowToUnlock !== -1) {
        for (let j = 1; j < grid[rowToUnlock].length - 1; j++) {
            grid[rowToUnlock][j].locked = false;
        }
    }

    // Figure out which cell to highlight
    let activeRow = -1;
    let activeCol = -1;
    if (!data.completed) {
        activeRow = rowWithEmptyField;
        activeCol = firstEmptyCell;
    }

    return {
        gridId: data.gridId,
        grid: grid,
        gridSymbols: gridSymbols,
        wordSize: data.numFields,
        activeX: activeRow,
        activeY: activeCol,
        solution: data.solution
    }
}

export function setEmptyGrids(numGuesses, numFields) {

    let grid = [];

    let initialCellConfig = getInitialGridCellConfig(
        config.games.props.meme.id
    );
    initialCellConfig.locked = true;

    for (let i = 0; i < numGuesses; i++) {
        grid[i] = []

        // Push first cell which is a simple number
        let initialCellConfig = getInitialGridCellConfig(
            config.games.props.meme.id
        );
        initialCellConfig.locked = true;
        initialCellConfig.value = (i + 1).toString()
        grid[i].push(initialCellConfig);

        // Push cells that user will be able to guess
        for (let j = 1; j <= numFields; j++) {
            initialCellConfig = getInitialGridCellConfig(
                config.games.props.meme.id
            );
            initialCellConfig.locked = true;
            initialCellConfig.value = ""
            initialCellConfig.solution = j;
            grid[i].push(initialCellConfig);
        }

        // Push last cell which is a result of guesses
        initialCellConfig = getInitialGridCellConfig(
            config.games.props.memory.id
        );
        initialCellConfig.locked = true;
        grid[i].push(initialCellConfig);
    }

    return {
        grid: grid,
        gridSymbols: [0, 0, 0, 0],
        wordSize: numFields,
    };
}

export function calculateRowResult(grid, rowId, solution) {

    let rowFilled = true;
    let filledValues = []
    for (let j = 1; j < grid[rowId].length - 1; j++) {
        if (grid[rowId][j].value === "" || grid[rowId][j].value === 0) {
            rowFilled = false;
            break;
        }
        filledValues.push(parseInt(grid[rowId][j].value));
    }

    if (!rowFilled) {
        return null;
    }

    let numFoundColorsAndCells = 0;
    let numPartiallyFound = 0;

    let coveredValues = []

    for (let i = 0; i < filledValues.length; i++) {
        if (solution[i] === filledValues[i]) {
            numFoundColorsAndCells = numFoundColorsAndCells + 1;
            coveredValues.push(filledValues[i]);
        }
    }

    for (let i = 0; i < filledValues.length; i++) {
        let val = filledValues[i];

        if (coveredValues.includes(val)) {
            continue
        }

        if (solution.includes(val)) {
            numPartiallyFound = numPartiallyFound + 1;
            coveredValues.push(val);
        }
    }

    let aHtml = getFullCorrectButton();
    let bHtml = getPartialCorrectButton();

    let cHtml = "<span></span>";

    for (let i = 0; i < numFoundColorsAndCells; i++) {
        cHtml = cHtml + aHtml;
    }

    for (let i = 0; i < numPartiallyFound; i++) {
        cHtml = cHtml + bHtml;
    }

    return {
        numFullFound: numFoundColorsAndCells,
        numPartialFound: numPartiallyFound,
        cHtml: cHtml
    }
}

export function findNextEmptyCellInRow(grid, rowId, colId) {
    for (let j = colId + 1; j < grid[rowId].length - 1; j++) {
        if (grid[rowId][j].value === "" || grid[rowId][j].value === 0) {
            return j;
        }
    }

    for (let j = 1; j < colId; j++) {
        if (grid[rowId][j].value === "" || grid[rowId][j].value === 0) {
            return j;
        }
    }
    return -1;
}

function processRow(grid, rowId, solution) {
    let data = calculateRowResult(grid, rowId, solution);
    if (data === null) {
        return grid;
    }

    grid[rowId][grid[rowId].length - 1].html = data.cHtml;
    grid[rowId][grid[rowId].length - 1].value = "";
    return grid;
}

function getColor(num) {
    switch (num) {
        case 1:
            return 'red';
        case 2:
            return 'green';
        case 3:
            return 'purple';
        case 4:
            return 'yellow';
        case 5:
            return 'blue';
        case 6:
            return 'orange';
        case 7:
            return 'pink';
        case 8:
            return 'cyan';
        case 9:
            return 'gold';
        default:
            return 'black';
    }
}

export function getValueClass(num) {
    let color = getColor(num);
    return "btn btn-lg btn-round-lg btn-" + color;
}

export function getButtonClass(num) {
    let color = getColor(num);
    return "btn btn-sm btn-round-sm btn-" + color;
}

export function setCellHtml(num) {
    if (num === 0) {
        return '<span></span>';
    }

    let valueClass = getValueClass(num);
    return `<button type="button" class="${valueClass}"></button>`;
}

export function getFullCorrectButton() {
    return "<i class=\"fa fa-xs fa-check\" style='color:black' aria-hidden=\"true\"></i>";
}

export function getPartialCorrectButton() {
    return "<i class=\"fa fa-xs fa-check\" style='color:white' aria-hidden=\"true\"></i>";
}

export function isRowCorrect(row) {
    for (let i = 1; i < row.length - 1; i++) {
        if (row[i].value !== row[i].solution) {
            return false;
        }
    }
    return true
}