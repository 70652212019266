export const messageSettings = {
    E: {
        RESIME: {
            INTERNAL: {},
            PERIODIC_INTERNAL: {},
            GAME_VALIDATION: {},
        },
        PAYMENT: {
            DATA_VALIDATION_ERROR: {},
            DATA_OLD_VALUES: {},
            PAYPAL_GENERIC_ERROR: {},
            PAYPAL_FATAL_ERROR: {},
            PAYPAL_DECLINED: {},
        },
    },
    I: {
        PAYMENT: {
            IN_PROGRESS: {
                showConfirmButton: false,
                showCancelButton: false,
            },
        },
        MENTORSHIP: {
            ALREADY_A_MENTOR: {}
        }
    },
    S: {
        PAYMENT: {
            PAYMENT_SUCCESS: {},
        },
        CHANGES: {
            SAVED: {}
        },
        REGISTER: {
            FIRST_LOGIN: {}
        },
        MENTORSHIP: {
            ADD_MEMBER_SUCCESS: {},
            MY_ASSIGNMENT_ARCHIVED: {}
        }
    }
};
