<script>
import Vue from "vue";
import { config } from "@/config/config";
import router from "../../config/PageRoutes";
import {
  findNextNotLocked,
  setSelectedPosition,
  processResult,
  resetGridData, commonStoreState, setInitialState, updateGameData,
} from "@/config/stores/common";
import {
  setGrids,
  setEmptyGrids,
  setState,
} from "@/_helpers/games/kakuro-helper";
import { gamesService } from "@/_services";
import { handleError } from "@/_helpers/api";

const kakuroStore = {
  namespaced: true,
  state: {
    ...commonStoreState,
    // game specific vars
    wordSize: 0,
    step: null,
    selected: null,
    selectedWord: null,
    selectedCoords: [],
    wordFound: false,
    numRows: 0,
    numCols: 0,
  },
  mutations: {
    async initGrid(state, gridData) {
      const period = gridData.period;
      const gridId = gridData.gridId;

      let emptyGrids = setEmptyGrids(6, 6);

      state.completed = false;
      setState(state, emptyGrids);

      setInitialState(state);
      state.period = period;
      state.gridId = gridId;
      state.selectedWord = null;
      state.selectedCoords = [];

      if (period !== "") {
        gamesService
          .get_periodic(config.games.props.kakuro.id, period)
          .then((data) => {
            let grids = setGrids(data, 3);
            state.completed = data.completed;
            setState(state, grids);

            processResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      } else if (gridId !== "") {
        gamesService
          .get(config.games.props.kakuro.id, gridId)
          .then((data) => {
            let grids = setGrids(data, 3);
            state.completed = data.completed;
            setState(state, grids);

            processResult(state);
          })
          .catch((error) => handleError(error, router, this._vm));
      }
    },
    // eslint-disable-next-line no-unused-vars
    resetGrid(state, value) {
      resetGridData(state);

      state.selected = null;

      processKakuroResult(state, true);
    },
    // Function selects or deselects certain field
    setSelected(state, pos) {
      if (state.solutionAchieved) return;

      setSelectedPosition(state, pos);
    },
    // Function sets value to certain cell
    setValue(state, num) {
      if (state.solutionAchieved) return;

      // Do not set value is nothing is selected
      if (!state.selected) return;

      // Make sure that user entered integer
      let parsedValue = parseInt(num);
      if (isNaN(parsedValue) || !Number.isInteger(parsedValue)) {
        parsedValue = "";
      }

      // Do not change value if value is the same
      if (state.grid[state.selected.x][state.selected.y].value === parsedValue)
        return;

      state.grid[state.selected.x][state.selected.y].value = parsedValue;
      Vue.set(state.grid, state.selected.x, state.grid[state.selected.x]);

      processKakuroResult(state);
    },
    moveInDirection(state, direction) {
      if (state.solutionAchieved) return;

      let new_pos = findNextNotLocked(state, direction);
      if (new_pos.x !== -1 && new_pos.y !== -1) {
        setSelectedPosition(state, new_pos);
      }
    },
  },
};

function processKakuroResult(state, initialState = false) {
  // Do not invoke APIs if resetting already reset state
  if (initialState && state.initialState) {
    return;
  }

  state.initialState = initialState;

  processResult(state);

  if (!state.completed) {
    updateKakuroInfo(state);
    if (state.solutionAchieved) {
      state.completed = true;
    }
  }
}

function updateKakuroInfo(state) {
  if (state.selected === null) {
    return;
  }

  const change = {
    x: state.selected.x,
    y: state.selected.y,
    v: state.grid[state.selected.x][state.selected.y].value,
  };

  const data = {
    gridId: state.gridId,
    change: change,
    completed: state.solutionAchieved,
  };

  updateGameData(config.games.props.kakuro.id, data, state.period)
}

export default kakuroStore;
</script>
