<script>
import {
  setState,
} from "@/_helpers/games/math-crossword-helper";
import {setSampleCrosswordGrids} from "@/_helpers/games/crossword-helper";
import {commonStoreState, setInitialState, setSelectedPosition} from "@/config/stores/common";


const crosswordStore = {
  namespaced: true,
  state: {
    ...commonStoreState,
    // game specific vars
    wordSize: 0,
    step: null,
    selected: null,
    selectedWord: null,
    selectedCoords: [],
    wordFound: false,
    numRows: 0,
    numCols: 0,
    hintsE: [],
    hintsS: [],
  },
  mutations: {
    async initGrid(state, gridData) {
      const period = gridData.period;
      const gridId = gridData.gridId;

      let emptyGrids = setSampleCrosswordGrids(8, 8, 3);

      state.completed = false;
      setState(state, emptyGrids);

      setInitialState(state);
      state.period = period;
      state.gridId = gridId;
      state.selectedWord = null;
      state.selectedCoords = [];
    },
    //     setGrid(state, id){
    //         getAndShowGrid(state, id);
    //     },
    //     showCurrentState(state) {
    //         toggleWrongValues(state);
    //     },
    //     // Function selects or deselects certain field
    setSelected(state, pos) {
      if(state.solutionAchieved)
          return;

      setSelectedPosition(state, pos);
    },
    //     resetGrid(state, value) {
    //         for (let i = 0; i < state.grid.length; i++) {
    //             for (let x = 0; x < state.grid[i].length; x++) {
    //                 state.grid[i][x].letter = "";
    //                 state.grid[i][x].selected = false;
    //                 state.grid[i][x].wrong_value = false;
    //                 state.grid[i][x].correct_value = false;
    //                 Vue.set(state.grid, i, state.grid[i]);
    //             }
    //         }
    //         processResult(state);
    //     },
    //     moveInDirection(state, direction) {
    //         if(state.solutionAchieved)
    //             return;
    //
    //         let new_pos = findNextNotLocked(state, direction);
    //         if (new_pos.x !== -1 && new_pos.y !== -1) {
    //             setSelectedPosition(state, new_pos);
    //         }
    //     },
  },
  actions: {},
};

// async function getAndShowGrid(state, id){
//     const res = await axios.post("/api/v1/crossword/get", { id: id });
//
//     for(let i = 0; i < state.gridList.length; i++){
//         if(state.gridList[i].id === id){
//             state.activeGridId = state.gridList[i].id;
//             state.activeGridName = state.gridList[i].name;
//             break;
//         }
//     }
//
//     state.grid = res.data.grid;
//     state.solutionAchieved = false;
//     state.allFilledUp = false;
//
//     for (let i = 0; i < state.grid.length; i++) {
//         for (let x = 0; x < state.grid[i].length; x++) {
//
//             let letter = state.grid[i][x].letter;
//             if(letter !== null){
//                 letter = letter.toUpperCase();
//             }
//
//             let newVal = {
//                 type: state.grid[i][x].type,
//                 letter: letter,
//                 original_letter: letter,
//                 solution: letter,
//                 hint_down: state.grid[i][x].hint_down,
//                 hint_right: state.grid[i][x].hint_right,
//                 locked: state.grid[i][x].type !== "LETTER",
//                 selected: false,
//                 wrong_value: false,
//                 correct_value: false,
//             };
//
//             state.grid[i][x] = newVal;
//         }
//     }
// }
//
// // Function finds next not locked cell in certain direction
// // Param:
// //    - direction:
// //        - 0: UP
// //        - 1: DOWN
// //        - 2: LEFT
// //        - 3: RIGHT
// function findNextNotLocked(state, direction) {
//     let selected_row = 0;
//     let selected_col = 0;
//
//     let new_pos = {
//         x: -1,
//         y: -1
//     };
//
//     if (state.selected === null) {
//         return new_pos;
//     }
//
//     if (state.selected !== null) {
//         selected_row = state.selected.x;
//         selected_col = state.selected.y;
//     }
//
//     if (direction === 0) {
//         let current_row = selected_row;
//         while (current_row > 0) {
//             current_row--;
//             let field = state.grid[current_row][selected_col];
//             if (field.locked === false) {
//                 new_pos.x = current_row;
//                 new_pos.y = selected_col;
//                 break;
//             }
//         }
//     } else if (direction === 1) {
//         let current_row = selected_row;
//         while (current_row < state.grid.length - 1) {
//             current_row++;
//             let field = state.grid[current_row][selected_col];
//             if (field.locked === false) {
//                 new_pos.x = current_row;
//                 new_pos.y = selected_col;
//                 break;
//             }
//         }
//     } else if (direction === 2) {
//         let current_col = selected_col;
//         while (current_col > 0) {
//             current_col--;
//             let field = state.grid[selected_row][current_col];
//             if (field.locked === false) {
//                 new_pos.x = selected_row;
//                 new_pos.y = current_col;
//                 break;
//             }
//         }
//     } else if (direction === 3) {
//         let current_col = selected_col;
//         while (current_col < state.grid[0].length - 1) {
//             current_col++;
//             let field = state.grid[selected_row][current_col];
//             if (field.locked === false) {
//                 new_pos.x = selected_row;
//                 new_pos.y = current_col;
//                 break;
//             }
//         }
//     }
//
//     return new_pos;
// }
//
// Function selects or deselects certain field
//
// function areAllValuesFilledUp(state) {
//     for (let i = 0; i < state.grid.length; i++) {
//         for (let x = 0; x < state.grid[i].length; x++) {
//             if(state.grid[i][x].type === "LETTER"){
//                 if(state.grid[i][x].letter === "" || !state.grid[i][x].letter)
//                     return false;
//             }
//         }
//     }
//     return true;
// }
//
// function hasWrongValues(state) {
//     for (let i = 0; i < state.grid.length; i++) {
//         for (let x = 0; x < state.grid[i].length; x++) {
//             if(state.grid[i][x].type === "LETTER"){
//                 if(state.grid[i][x].letter !== state.grid[i][x].solution)
//                     return true;
//             }
//         }
//     }
//     return false;
// }
//
// function toggleWrongValues(state){
//     if(state.stateShown){
//         setWrongValues(state, false);
//     }
//     else{
//         setWrongValues(state, true);
//     }
// }
//
// function setWrongValues(state, value) {
//     state.stateShown = value;
//     for (let i = 0; i < state.grid.length; i++) {
//         for (let x = 0; x < state.grid[i].length; x++) {
//             if(state.grid[i][x].type === "LETTER"){
//                 if(value === false){
//                     state.grid[i][x].wrong_value = false;
//                     state.grid[i][x].correct_value = false;
//                 }
//                 else{
//                     if(state.grid[i][x].letter !== ""){
//                         if(state.grid[i][x].letter !== state.grid[i][x].solution){
//                             state.grid[i][x].wrong_value = true;
//                             state.grid[i][x].correct_value = false;
//                         }
//                         else{
//                             state.grid[i][x].wrong_value = false;
//                             state.grid[i][x].correct_value = true;
//                         }
//                     }
//                     else{
//                         state.grid[i][x].wrong_value = false;
//                         state.grid[i][x].correct_value = false;
//                     }
//                 }
//                 Vue.set(state.grid, i, state.grid[i]);
//             }
//         }
//     }
// }
//
// function processResult(state) {
//
//     setWrongValues(state, false);
//     state.allFilledUp = areAllValuesFilledUp(state);
//     state.solutionAchieved = false;
//
//     if(state.allFilledUp){
//         let areWrongValues = hasWrongValues(state);
//         setWrongValues(state, areWrongValues);
//         state.solutionAchieved = !areWrongValues;
//     }
// }

export default crosswordStore;
</script>
