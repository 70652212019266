import {userService} from "@/_services";
import router from "../../config/PageRoutes";
import {getLocalStorageUserItemName} from "@/_helpers/domain_helper";

const user = JSON.parse(localStorage.getItem(getLocalStorageUserItemName()));
const state = user
    ? {status: {loggedIn: true}, user}
    : {status: {}, user: null};

const actions = {
    loginGoogleOauth2({dispatch, commit}, jwtToken) {
        commit("loginRequest", {jwtToken});

        if (!jwtToken) {
            commit("loginFailure", "");
            router.push("/login");
        }

        userService.loginGoogleOauth2(jwtToken).then(
            (user) => {
                commit("loginSuccess", user);
                // Reload upon login to fetch new data if available
                window.location.href = "/home";
            },
            (error) => {
                console.log(error);
                commit("loginFailure", error);
                dispatch("alert/error", error, {root: true});
            }
        );
    },
    logout({commit}) {
        userService.logout();
        commit("logout");
    },
};

const mutations = {
    loginRequest(state, user) {
        state.status = {loggingIn: true};
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = {loggedIn: true};
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    unsetNewUser(state) {
        state.user.new_user = false;
        localStorage.setItem(getLocalStorageUserItemName(), JSON.stringify(state.user));
    },
    updateCredit(state, credit) {
        state.user.credit_amount = credit;
        localStorage.setItem(getLocalStorageUserItemName(), JSON.stringify(state.user));
    },
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations,
};
