import {getInitialGridCellConfig} from "@/config/stores/common";
import {config} from "@/config/config";

export function setEmptyGrids(numRows, numCols) {
    let grid = [];

    let initialCellConfig = getInitialGridCellConfig(
        config.games.props.simpleMath.id
    );
    initialCellConfig.locked = true;

    for (let i = 0; i < numRows; i++) {
        grid[i] = [];
        for (let j = 0; j < numCols; j++) {
            grid[i].push(initialCellConfig);
        }
    }

    return {
        gridId: null,
        grid: grid,
        gameType: null,
        gameNumbers: null,
        gridSymbols: null,
    };
}

export function setState(state, grids) {
    state.gridId = grids.gridId;
    state.grid = grids.grid;
    state.gameType = grids.gameType;
    state.gameNumbers = grids.gameNumbers;
    state.gridSymbols = grids.gridSymbols;

    state.selectedCoords = [];
    if ("foundCoords" in grids && grids.foundCoords !== undefined) {
        state.selectedCoords = grids.foundCoords;
    }

    if (state.completed) {
        state.selectedCoords = state.gridSymbols;
    }
}

export function setGrids(data) {
    let grid = [];

    for (let i = 0; i < data.grid.length; i++) {
        grid[i] = [];
        for (let j = 0; j < data.grid[i].length; j++) {
            let selected = false;
            if ("found_symbols" in data) {
                selected = data.found_symbols.some((r) => r[0] === i && r[1] === j);
            }

            const gObj = {
                original_value: data.grid[i][j],
                solution: data.grid[i][j],
                value: data.grid[i][j],
                selected: selected,
            };

            grid[i].push(gObj);
        }
    }

    return {
        gridId: data.gridId,
        grid: grid,
        gameType: data.game_type,
        gameNumbers: data.game_numbers,
        gridSymbols: data.value_coords,
        foundCoords: data.found_symbols,
    };
}