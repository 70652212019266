import {config} from "@/config/config";
import {getInitialGridCellConfig} from "@/config/stores/common";

export function setEmptyGrid(numRows, numCols) {
    let initialCellConfig = getInitialGridCellConfig(
        config.games.props.sudoku.id
    );
    // Manually setting it to true so user in initial state cannot click on anything
    initialCellConfig.locked = true;
    initialCellConfig.t = "M";
    initialCellConfig.n = 0;
    initialCellConfig.u = "I";

    let grid = [];
    for (let i = 0; i < numRows; i++) {
        grid[i] = [];
        for (let j = 0; j < numCols; j++) {
            grid[i][j] = initialCellConfig;
        }
    }

    return grid;
}

export function setGrid(initialGrid) {
    let grid = [];
    for (let i = 0; i < initialGrid.length; i++) {
        grid[i] = [];
        for (let j = 0; j < initialGrid[i].length; j++) {
            grid[i][j] = {
                t: initialGrid[i][j].t,
                n: initialGrid[i][j].n,
                u: initialGrid[i][j].u,
            };
        }
    }

    return grid;
}
