<script>
import Vue from "vue";
import {config} from "@/config/config";
import router from "../../config/PageRoutes";
import {includes} from "lodash";
import {
  findNextNotLocked,
  processResult,
  setSelectedPosition,
  resetGridData, commonStoreState, setInitialState, updateGameData,
} from "@/config/stores/common";
import {
  setGrids,
  setEmptyGrids,
  setState,
} from "@/_helpers/games/caesar-cypher-helper";
import {gamesService} from "@/_services";
import {handleError} from "@/_helpers/api";

const caesarCypherStore = {
  namespaced: true,
  state: {
    ...commonStoreState,
    // game specific vars
    alphabet: null,
    gridSymbols: null,
    description: null,
    step: null,
    selected: null,
  },
  mutations: {
    async initGrid(state, gridData) {
      const period = gridData.period;
      const gridId = gridData.gridId;

      const maxWidth = 10;

      const emptyGrids = setEmptyGrids(maxWidth, maxWidth * 2);
      setState(state, emptyGrids);

      setInitialState(state);
      state.period = period;
      state.gridId = gridId;
      state.selected = null;

      if (period !== "") {
        gamesService
            .get_periodic(config.games.props.caesarCypher.id, period)
            .then((data) => {
              const gridData = setGrids(maxWidth, data);
              setState(state, gridData);

              state.stateShown = false;
              state.solutionAchieved = false;
              state.hasWrongValues = false;
              state.allFilledUp = false;

              processResult(state);
            })
            .catch((error) => handleError(error, router, this._vm));
      } else if (gridId !== "") {
        gamesService
            .get(config.games.props.caesarCypher.id, gridId)
            .then((data) => {
              const gridData = setGrids(maxWidth, data);
              setState(state, gridData);

              state.stateShown = false;
              state.solutionAchieved = false;
              state.hasWrongValues = false;
              state.allFilledUp = false;

              processResult(state);
            })
            .catch((error) => handleError(error, router, this._vm));
      }
    },
    // eslint-disable-next-line no-unused-vars
    resetGrid(state, value) {
      resetGridData(state);
      processCaesarCypherResult(state, true);
    },
    // Function selects or deselects certain field
    setSelected(state, pos) {
      if (state.solutionAchieved) return;

      setSelectedPosition(state, pos);
    },
    // Function sets value to certain cell
    setValue(state, val) {
      if (state.solutionAchieved) return;

      // Do not set value is nothing is selected
      if (!state.selected) return;

      // Is it a valid value
      let upperAlphabet = state.alphabet.map((l) => l.toUpperCase());
      let upperVal = val.toString().toUpperCase();
      if (!includes(upperAlphabet, upperVal)) return;

      // Do not change value if value is the same
      if (state.grid[state.selected.x][state.selected.y].value === upperVal)
        return;

      state.grid[state.selected.x][state.selected.y].value = upperVal;
      Vue.set(state.grid, state.selected.x, state.grid[state.selected.x]);

      processCaesarCypherResult(state);
    },
    // Function sets number to certain cell
    unsetValue(state) {
      if (state.solutionAchieved) return;

      // Do not set value is nothing is selected
      if (!state.selected) return;

      // Do not unset value if it is already
      if (state.grid[state.selected.x][state.selected.y].value === "") {
        return;
      }

      state.grid[state.selected.x][state.selected.y].value = "";
      Vue.set(state.grid, state.selected.x, state.grid[state.selected.x]);

      processCaesarCypherResult(state);
    },
    moveInDirection(state, direction) {
      if (state.solutionAchieved) return;

      let new_pos = findNextNotLocked(state, direction);
      if (new_pos.x !== -1 && new_pos.y !== -1) {
        setSelectedPosition(state, new_pos);
      }
    },
  },
  actions: {},
};

function processCaesarCypherResult(state, initialState = false) {
  // Do not invoke APIs if resetting already reset state
  if (initialState && state.initialState) {
    return;
  }
  state.initialState = initialState;

  processResult(state);

  if (!state.completed) {
    updateUserCaesarCypherInfo(state);
    if (state.solutionAchieved) {
      state.completed = true;
    }
  }
}

function updateUserCaesarCypherInfo(state) {
  let partialText = [];

  for (let i = 1; i < state.grid.length; i += 3) {
    for (let j = 0; j < state.grid[i].length; j++) {
      partialText.push(state.grid[i][j].value);
    }
  }

  let data = {
    gridId: state.gridId,
    partialText: partialText,
    completed: state.solutionAchieved,
  };

  updateGameData(config.games.props.caesarCypher.id, data, state.period)
}

export default caesarCypherStore;
</script>
