import {includes} from "lodash";
import {config} from "@/config/config";
import {getInitialGridCellConfig} from "@/config/stores/common";

export function setEmptyGrids(maxWidth, wordSize) {
    let gridSymbols = [];

    // Fill gridSymbols
    gridSymbols[0] = [];
    for (let i = 0; i < maxWidth; i++) {
        gridSymbols[0].push("");
    }

    // Number of (encryptedLine, decryptedLine, emptyLine) triplets
    const numTriplets = Math.floor(wordSize / maxWidth) + 1;

    let initialCellConfig = getInitialGridCellConfig(
        config.games.props.caesarCypher.id
    );
    initialCellConfig.type = "ENCRYPTED";
    initialCellConfig.locked = true;

    let initialEmptyCellConfig = getInitialGridCellConfig(
        config.games.props.caesarCypher.id
    );
    initialEmptyCellConfig.type = "EMPTY";

    let partialGrid = [];
    for (let i = 0; i < numTriplets; i++) {
        // Init triplet
        const startX = i * 3;
        partialGrid[startX] = [];
        partialGrid[startX + 1] = [];
        partialGrid[startX + 2] = [];

        const lettersRemaining = wordSize - i * maxWidth;
        let numInRow = Math.min(maxWidth, lettersRemaining);

        // Fill triplets
        for (let j = 0; j < numInRow; j++) {
            partialGrid[startX][j] = initialCellConfig;
            partialGrid[startX + 1][j] = initialCellConfig;
            partialGrid[startX + 2][j] = initialEmptyCellConfig;
        }
    }

    return {
        gridId: null,
        alphabet: null,
        gridSymbols: gridSymbols,
        step: 0,
        completed: false,
        grid: partialGrid,
        description: null,
    };
}

export function setGrids(maxWidth, data) {
    const wordSize = data.encrypted_text.length;
    const encryptedWord = data.encrypted_text;
    const decryptedWord = data.decrypted_text;
    const userValue = data.partial_text;
    const alphabet = data.alphabet;
    const description = data.description;

    let gridSymbols = [];

    let gIndex = 0;
    gridSymbols[gIndex] = [];
    for (let i = 0; i < alphabet.length; i++) {
        if (gridSymbols[gIndex].length === maxWidth) {
            gIndex++;
            gridSymbols[gIndex] = [];
        }
        gridSymbols[gIndex].push(alphabet[i]);
    }

    // Number of (encryptedLine, decryptedLine, emptyLine) triplets
    const numTriplets = Math.floor(wordSize / maxWidth) + 1;

    let emptyVal = {
        type: "EMPTY",
        locked: true,
        selected: false,
        value: "",
        original_value: "",
        solution: "",
        wrong_value: false,
        correct_value: false,
    };

    let partialGrid = [];
    for (let i = 0; i < numTriplets; i++) {
        // Init triplet
        const startX = i * 3;
        partialGrid[startX] = [];
        partialGrid[startX + 1] = [];
        partialGrid[startX + 2] = [];

        const lettersRemaining = wordSize - i * maxWidth;
        let numInRow = Math.min(maxWidth, lettersRemaining);

        // Fill triplets
        for (let j = 0; j < numInRow; j++) {
            const wordIndex = i * maxWidth + j;

            let encryptedVal = {
                type: "ENCRYPTED",
                locked: true,
                selected: false,
                value: encryptedWord[wordIndex],
                original_value: encryptedWord[wordIndex],
                solution: encryptedWord[wordIndex],
                wrong_value: false,
                correct_value: false,
            };

            let decLocked = false;
            let decValue = userValue[wordIndex];

            if (
                decryptedWord[wordIndex] === "" ||
                !includes(alphabet, decryptedWord[wordIndex])
            ) {
                decLocked = true;
                decValue = decryptedWord[wordIndex];
            }

            let decryptedVal = {
                type: "DECRYPTED",
                locked: decLocked,
                selected: false,
                value: decValue,
                original_value: decValue,
                solution: decryptedWord[wordIndex],
                wrong_value: false,
                correct_value: false,
            };

            partialGrid[startX][j] = encryptedVal;
            partialGrid[startX + 1][j] = decryptedVal;
            partialGrid[startX + 2][j] = emptyVal;
        }

        if (lettersRemaining < maxWidth) {
            for (let j = numInRow; j < maxWidth; j++) {
                partialGrid[startX][j] = emptyVal;
                partialGrid[startX + 1][j] = emptyVal;
                partialGrid[startX + 2][j] = emptyVal;
            }
        }
    }

    return {
        gridId: data.gridId,
        alphabet: data.alphabet,
        gridSymbols: gridSymbols,
        step: data.step,
        completed: data.completed,
        grid: partialGrid,
        description: description,
    };
}

export function setState(state, grids) {
    state.gridId = grids.gridId;
    state.alphabet = grids.alphabet;
    state.description = grids.description;
    state.gridSymbols = grids.gridSymbols;
    state.step = grids.step;
    state.completed = grids.completed;
    state.grid = grids.grid;
}
