import {fetcher, handleResponse} from "@/_helpers/api";
import {
  addAssignmentMethod,
  addAssignmentUrl, archiveAssignmentMethod, archiveAssignmentUrl, archiveMyAssignmentMethod, archiveMyAssignmentUrl,
  getAssignmentMethod, getAssignmentPriceMethod, getAssignmentPriceUrl,
  getAssignmentUrl,
  getMyAssignmentMethod,
  getMyAssignmentsMethod,
  getMyAssignmentsUrl,
  getMyAssignmentUrl,
  listAssignmentsMethod,
  listAssignmentsUrl, updateMyAssignmentMethod, updateMyAssignmentUrl
} from "@/_helpers/assignments_helper";

export const assignmentService = {
  listAssignments,
  getAssignment,
  getMyAssignments,
  getMyAssignment,
  addAssignment,
  updateMyAssignment,
  archiveMyAssignment,
  archiveAssignment,
  getAssignmentPrice,
}

function listAssignments(entity, entityId, user_status = "ACTIVE") {
  let requestOptions = {
    method: listAssignmentsMethod(),
    headers: { "Content-Type": "application/json" },
  };

  let url = listAssignmentsUrl(entity, entityId);
  url += `?userStatus=${user_status}`;

  return fetcher(url, requestOptions).then(handleResponse);
}

function getAssignment(assignmentId) {
  let requestOptions = {
    method: getAssignmentMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getAssignmentUrl(assignmentId), requestOptions).then(handleResponse);
}

function getMyAssignments(user_status = "ACTIVE") {
  let requestOptions = {
    method: getMyAssignmentsMethod(),
    headers: { "Content-Type": "application/json" },
  };

  let url = getMyAssignmentsUrl()
  url += `?userStatus=${user_status}`;

  return fetcher(url, requestOptions).then(handleResponse);
}

function getMyAssignment(assignmentId) {
  let requestOptions = {
    method: getMyAssignmentMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getMyAssignmentUrl(assignmentId), requestOptions).then(handleResponse);
}

function getAssignmentPrice() {
  let requestOptions = {
    method: getAssignmentPriceMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getAssignmentPriceUrl(), requestOptions).then(handleResponse);
}

function addAssignment(studentGroupId, data) {
  let requestOptions = {
    method: addAssignmentMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetcher(addAssignmentUrl(), requestOptions).then(handleResponse);
}

function updateMyAssignment(assignmentId, data) {
  let requestOptions = {
    method: updateMyAssignmentMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetcher(updateMyAssignmentUrl(assignmentId), requestOptions).then(handleResponse);
}

function archiveMyAssignment(assignmentId) {
  let requestOptions = {
    method: archiveMyAssignmentMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
  };

  return fetcher(archiveMyAssignmentUrl(assignmentId), requestOptions).then(handleResponse);
}

function archiveAssignment(assignmentId) {
  let requestOptions = {
    method: archiveAssignmentMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
  };

  return fetcher(archiveAssignmentUrl(assignmentId), requestOptions).then(handleResponse);
}